import React from "react";
import Header from "./Header";
import Footer from "./Footer";

function Clientdetails1() {
  return (
    <div>
      <Header />
      <div className="page-header parallaxie">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              {/* Page Header Box Start */}
              <div className="page-header-box">
                <h1 className="text-anime">Our Client Details</h1>
                <nav className="wow fadeInUp" data-wow-delay="0.25s">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <a href="/">Home</a>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      Client Details
                    </li>
                  </ol>
                </nav>
              </div>
              {/* Page Header Box End */}
            </div>
          </div>
        </div>
      </div>
      <div className="page-blog-archive">
        <div className="container">
            <h3>Vagdevi College</h3>
          <div className="row mb-5 mt-5">
            <div className="col-lg-4 col-md-6">
              {/* Blog Item Start */}
              <div className="blog-item wow fadeInUp" data-wow-delay="0.25s">
                <div className="post-featured-image">
                  <figure className="image-anime">
                    <img src="images/l1.jpg" alt="" />
                  </figure>
                </div>
              </div>
              {/* Blog Item End */}
            </div>
            <div className="col-lg-4 col-md-6">
              {/* Blog Item Start */}
              <div className="blog-item wow fadeInUp" data-wow-delay="0.5s">
                <div className="post-featured-image">
                  <figure className="image-anime">
                    <img src="images/l2.jpg" alt="" />
                  </figure>
                </div>
              </div>
              {/* Blog Item End */}
            </div>
            <div className="col-lg-4 col-md-6">
              {/* Blog Item Start */}
              <div className="blog-item wow fadeInUp" data-wow-delay="0.75s">
                <div className="post-featured-image">
                  <figure className="image-anime">
                    <img src="images/l3.jpg" alt="" />
                  </figure>
                </div>
              </div>
              {/* Blog Item End */}
            </div>
         
           
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default Clientdetails1;
