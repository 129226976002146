import React, { useState, useRef, useEffect } from "react";
import Header from "./Header";
import Footer from "./Footer";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination, EffectCoverflow } from "swiper/modules";
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
import "swiper/css/navigation";
import CountUp from "react-countup";
import about1 from "../images/about/about1.jpg";
import about2 from "../images/about/about2.jpg";
import relief from "../images/project/relief.jpg";
import loadernew from "../images/loadernew.gif";
import choose from "../images/choose.jpg";
import icon from "../images/icon.jpg";
import iconwomen from "../images/iconwomen.jpg";
import homebanner1 from "../images/homebanner1.jpg";
import compressvedio from "../video/vedios.mp4";

function Home() {
  const iframeRef = useRef(null);
  const [isLoading, setIsLoading] = useState(true);
  const vidRef = useRef();
  const loadimg = () => {
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  };
  useEffect(() => {
    loadimg();

    // const removeTags = () => {
    //   const iframe = iframeRef.current;
    //   if (iframe) {
    //     const doc = iframe.contentDocument || iframe.contentWindow.document;

    //     doc.body.innerHTML = "";
    //   }
    // };

    // removeTags();
  }, []);

  return (
    <div>
      {isLoading == true ? (
        <>
          <div
            style={{ zIndex: "9999999999999", height: "420px" }}
            className="text-center mt-3 mb-5"
          >
            <img
              src={loadernew}
              height="140px"
              style={{ marginTop: "250px" }}
            ></img>
          </div>
        </>
      ) : (
        <>
          <Header />

          <div className="container-fuild">
            <div>
              <video
                autoPlay
                muted
                src={compressvedio}
                className="videos"
                type="video/mov"
                loop
              />
            </div>
          </div>

          {/* <iframe
            style={{ width: "100%", height: "700px" }}
            src="https://www.youtube.com/embed/4wcO_tVvfuI?si=P3zqZln-USK33YDL&controls=0"
            title="YouTube video player"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerPolicy="strict-origin-when-cross-origin"
            allowFullScreen=""
          /> */}

          {/* <iframe
            ref={iframeRef}
            src="https://www.youtube.com/embed/4wcO_tVvfuI?si=sOQxcCIW3kJxDSIF"
            style={{ width: "100%", height: "700px" }}
            autoplay="true"
          ></iframe> */}

          {/* <iframe src='https://www.youtube.com/embed/4wcO_tVvfuI?si=sOQxcCIW3kJxDSIF?autoplay=1&mute=1'
                  frameBorder='0'
                  allow='autoplay; encrypted-media'
                  allowFullScreen
                  title='video'
                /> */}

          <div className="hero-layout2 hero-slider">
            <div className="hero-slider-layout2">
              <div className="swiper">
                <div className="swiper-wrapper">
                  <div className="swiper-slide">
                    <div className="hero-slide">
                      {/* Slider Image Start */}
                      <div className="hero-slider-image">
                        <img src={homebanner1} alt="" />
                      </div>
                      {/* Slider Image End */}
                      {/* Slider Content Start */}
                      <div className="container">
                        <div className="row align-items-center">
                          <div className="col-lg-12">
                            {/* Hero Layout 2 Content Box Start*/}
                            <div className="hero-layout2-box">
                              <div className="section-title">
                                {/* <h3 className="wow fadeInUp">Welcome to Solor.</h3> */}
                                <h1 className="text-anime">
                                  Go Solar With
                                  <br />
                                  <span>
                                    ‘PM Surya Ghar Muft Bijli Yojana’{" "}
                                  </span>
                                </h1>
                                <p
                                  style={{ color: "white", fontSize: "18px" }}
                                  className="mt-2"
                                >
                                  Get up to ₹ 78,000 subsidy and reduce your
                                  electricity bills by 90%
                                </p>
                              </div>
                              {/* <div className="hero-content wow fadeInUp" data-wow-delay="0.25s">
                                <p>Duis ultricies, tortor a accumsan fermentum, purus diam mollis velit, eu bibendum ipsum erat quis leo. Vestibulum finibus, leo dapibus feugiat rutrum, augue lacus rhoncus velit, vel scelerisque odio est.</p>
                              </div> */}
                              <div
                                className="hero-button wow fadeInUp"
                                data-wow-delay="0.5s"
                              >
                                <a
                                  href="https://www.pmsuryaghar.gov.in/consumerLogin"
                                  className="btn-default"
                                  target="blank"
                                >
                                  Click Here
                                </a>
                                {/* <a href="#" className="btn-default btn-border">Contact Now</a> */}
                              </div>
                            </div>
                            {/* Hero Layout 2 Content Box End*/}
                          </div>
                        </div>
                      </div>
                      {/* Slider Content End */}
                    </div>
                  </div>

                  {/* <div className="swiper-slide">
                <div className="hero-slide">
               
                  <div className="hero-slider-image">
                    <img src="images/hero.jpg" alt="" />
                  </div>
                  
                  <div className="container">
                    <div className="row align-items-center">
                      <div className="col-lg-12">
                     
                        <div className="hero-layout2-box">
                          <div className="section-title">
                          
                            <h1 className="text-anime">Energize Society By <span>Reliable Energy!</span></h1>
                          </div>
                        
                          <div className="hero-button wow fadeInUp" data-wow-delay="0.5s">
                            <a href="#" className="btn-default">Our Services</a>
                          
                          </div>
                        </div>
                       
                      </div>
                    </div>
                  </div>
               
                </div>
              </div> */}
                </div>
                {/* <div className="hero-button-prev" />
            <div className="hero-button-next" /> */}
              </div>
            </div>
          </div>
          <div className="features-layout2">
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <div className="features-layout2-box">
                    <div className="row no-gap">
                      <div className="col-md-4">
                        <div
                          className="features-item2 wow fadeInUp"
                          data-wow-delay="0.25s"
                        >
                          <div className="features-header">
                            <div className="icon-box">
                              <img src="images/icon-feature-1.svg" alt="" />
                            </div>
                            <h3>Trust &amp; Warrenty</h3>
                          </div>
                          <div className="features-desc">
                            <p>
                              A solar panel manufacturer may provide a product
                              or performance warranty of 25-30 years that will
                              cover a replacement panel should you need it.and
                              they'll cover the labor and shipping also Inverter
                              performance Warranty 5-8 years.{" "}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="features-item3 features-active">
                          <div className="features-header">
                            <div className="icon-box">
                              <img src="images/icon-feature-2.svg" alt="" />
                            </div>
                            <h3>High Quality Work</h3>
                          </div>
                          <div className="features-desc">
                            <p>
                              High-quality solar panels are engineered to
                              capture and convert sunlight into electricity with
                              optimal efficiency.Rigorous Quality Control
                              inspections ensure .
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div
                          className="features-item3 wow fadeInUp"
                          data-wow-delay="0.25s"
                        >
                          <div className="features-header">
                            <div className="icon-box">
                              <img src="images/icon-feature-3.svg" alt="" />
                            </div>
                            <h3>24*7 Support</h3>
                          </div>
                          <div className="features-desc">
                            <p>
                              Customer satisfaction for us starts with providing
                              top class products at affordable prices that will
                              work trouble free for a long time. Our service
                              staff always .
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="latest-post-layout2 ">
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <div className="section-title ">
                    <h3 className="wow fadeInUp ">SOLAR ROOFTOPS</h3>
                    <h2
                      className="text-anime"
                      data-aos="fade-right"
                      data-aos-offset="300"
                      data-wow-duration="1000ms"
                      data-aos-easing="ease-in-sine"
                    >
                      Sri Balaji Solar Energies specializes in offering
                      Residential Solar Rooftops as well as commercial and
                      industrial rooftops.
                    </h2>
                  </div>
                  <p>
                    Solar rooftop are solar panels placed on top of roofs of
                    commercial, institutional or residential buildings. They
                    capture the light energy emitted by the sun and convert it
                    into electrical energy. This setup is also known as solar
                    rooftop photo-voltaic system.
                  </p>
                  {/* <p>Innovation is the need of the hour, and our research team lives up to the challenge and thinks out of the box to come up with new radical ideas. Our professionals educate the people and companies on the effective use of the free solar energy in their homes or industries.</p> */}
                </div>
              </div>
              <div className="row">
                <div className="col-lg-4 mb-4">
                  <div
                    className="post-item2 wow fadeInUp"
                    data-wow-delay="0.2s"
                  >
                    <div className="post-featured-image">
                      <figure className="image-anime">
                        <img src="images/ps1.jpg" alt="" />
                      </figure>
                      {/* <a href="/industrial-rooftops" className="blog-link"><i className="fas fa-link" /></a> */}
                    </div>
                    <div className="post-body">
                      <h2>
                        <a href="/industrial-rooftops">Industrial Rooftops</a>
                      </h2>
                      <p>
                        Industrial Solar Rooftops SBSC Solar Systems is an
                        established firm in India known for providing quality
                        centric solar panels.
                      </p>
                      <a href="/industrial-rooftops" class="btn-default">
                        Read More
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div
                    className="post-item2 wow fadeInUp"
                    data-wow-delay="0.4s"
                  >
                    <div className="post-featured-image">
                      <figure className="image-anime">
                        <img src="images/ps2.jpg" alt="" />
                      </figure>
                      {/* <a href="/commercial-rooftops" className="blog-link"><i className="fas fa-link" /></a> */}
                    </div>
                    <div className="post-body">
                      <h2>
                        <a href="/commercial-rooftops">Commercial Rooftops</a>
                      </h2>
                      <p>
                        Commercial Solar Rooftops SBSC Energy Systems, A pioneer
                        in the industry of solar energy.
                      </p>
                      <a href="/commercial-rooftops" class="btn-default">
                        Read More
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div
                    className="post-item2 wow fadeInUp"
                    data-wow-delay="0.6s"
                  >
                    <div className="post-featured-image">
                      <figure className="image-anime">
                        <img src="images/ps3.jpg" alt="" />
                      </figure>
                      {/* <a href="/Residential-Rooftops" className="blog-link"><i className="fas fa-link" /></a> */}
                    </div>
                    <div className="post-body">
                      <h2>
                        <a href="/Residential-Rooftops">Residential Rooftops</a>
                      </h2>
                      <p>
                        Solar Energy Systems is a leading name in the industry
                        of solar power. With all the expertise we earned over
                        these years.
                      </p>
                      <a href="/Residential-Rooftops" class="btn-default">
                        Read More
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="about-layout2">
            <div className="container">
              <div className="row align-items-center">
                <div className="col-lg-6">
                  <div className="about-image">
                    <div className="homeabout-img-1">
                      <figure
                        className="reveal image-anime"
                        data-aos="fade-right"
                        data-aos-offset="300"
                        data-wow-duration="1000ms"
                        data-aos-easing="ease-in-sine"
                      >
                        <img src={about1} alt="" />
                      </figure>
                    </div>
                    <div className="homeabout-img-2">
                      <figure
                        className="reveal image-anime"
                        data-aos="fade-right"
                        data-aos-offset="300"
                        data-wow-duration="1000ms"
                        data-aos-easing="ease-in-sine"
                      >
                        <img src={about2} alt="" />
                      </figure>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="about-layout2-content">
                    <div className="section-title">
                      <h3 className="wow fadeInUp">About Us</h3>
                      <h2
                        className="text-anime"
                        data-aos="fade-right"
                        data-aos-offset="300"
                        data-wow-duration="1000ms"
                        data-aos-easing="ease-in-sine"
                      >
                        About Green Energy Solar
                      </h2>
                    </div>
                    <div
                      className="about-body wow fadeInUp"
                      data-wow-delay="0.25s"
                    >
                      <p>
                        Sri Balaji Solar Energies (SBSE) was established in the
                        year 2011 by Mr. Ganesh Reddy Peddi and commenced
                        full-fledged operations in 2013. Since then, the company
                        has grown in stature and expertise, becoming a reputable
                        manufacturer and trader of a diverse range of solar
                        technology products. Our offerings include products
                        equipped with battery backups, ensuring functionality
                        even during night-time hours.
                      </p>
                      <p>
                        Mr. Ganesh Reddy Peddi, the founder of SBSE, also serves
                        as the Managing Director, providing strategic leadership
                        and vision to the company. Innovation is fundamental to
                        our ethos, and under his guidance, our dedicated
                        research team continually strives to think outside the
                        box, developing new and radical ideas to meet evolving
                        energy needs.
                      </p>
                      <p>
                        All life on Earth depends on the sun. This amazing
                        resource radiates energy and provides us both heat and
                        light by fusing hydrogen into helium at its core. We
                        call this solar radiation. But only about half of this
                        solar radiation makes it to the Earth’s surface because
                        the rest is either absorbed or reflected by clouds and
                        the atmosphere. Still, we receive enough sunlight from
                        the sun to meet the power demands of all mankind -
                        millions of times over. At Sri Balaji Solar Energies, we
                        harness this incredible power of the sun to drive
                        progress towards a sustainable future.
                      </p>
                    </div>
                    <div className="about-stats">
                      <div className="row">
                        <div className="col-md-3">
                          <div
                            className="about-footer wow fadeInUp"
                            data-wow-delay="1s"
                          >
                            <a
                              href="/about"
                              className="btn-default"
                              style={{ float: "right" }}
                            >
                              ReadMoreAbout
                            </a>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <h6> Solar Inverter Setup</h6>
                          <h6>Battery Storage Solutions</h6>
                          <h6> Solar Material Financing</h6>
                        </div>
                        <div className="col-md-5">
                          <h6>24 X 7 Call & Chat Support</h6>
                          <h6>Proven Track Record</h6>
                          <h6>Customer-Centric Approach</h6>
                        </div>
                        {/* <h6 className='col-md-4'> Solar Inverter Setup</h6>
                        <h6 className='col-md-4'>Battery Storage Solutions</h6>
                        <h6 className='col-md-4'> Solar Material Financing</h6>
                        <h6 className='col-md-6'>24 X 7 Call & Chat Support</h6>
                        <h6 className='col-md-6'>Proven Track Record</h6>
                        <h6 className='col-md-6'>Customer-Centric Approach</h6> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="why-chooseus-layout2">
            <div className="container-fluid">
              <div className="row no-gap row-equal-height">
                <div className="col-lg-6">
                  {/* <div className="why-choose-us-img-box">
                    <div >
                     
                        <img src={choose} alt="" />
                      
                      
                    </div>
                  </div> */}
                  <div className="why-choose-us-img-box">
                    <figure
                      className="reveal image-anime"
                      style={{
                        transform: "translate(0px, 0px)",
                        opacity: 1,
                        visibility: "inherit",
                      }}
                    >
                      <img
                        src={choose}
                        alt=""
                        style={{ transform: "translate(0px, 0px)" }}
                      />
                    </figure>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="why-choose-us-layout2-content">
                    <div className="section-title">
                      <h3 className="wow fadeInUp">Why Choose Us</h3>
                      <h2
                        className="text-anime"
                        data-aos="fade-right"
                        data-aos-offset="300"
                        data-wow-duration="1000ms"
                        data-aos-easing="ease-in-sine"
                      >
                        Providing Solar Energy Solutions
                      </h2>
                    </div>
                    <div
                      className="why-choose-us-body wow fadeInUp"
                      data-wow-delay="0.25s"
                    >
                      <p>
                        Sri Balaji Solar energies(SBSE) Established in the year
                        2013 and has grown in stature and expertise since then.
                        Manufacturer and Trader of a gamut of products. These
                        products work on the solar technology. They have battery
                        backups, which helps them function even during the
                        night.
                      </p>
                    </div>
                    <div className="why-choose-us-layout2-features">
                      <div
                        className="why-features-item wow fadeInUp"
                        data-wow-delay="0.5s"
                      >
                        <div className="icon-box">
                          <img src="images/icon-checkmark.svg" alt="" />
                        </div>
                        <div className="why-features-desc">
                          <h3>24*7 Support</h3>
                          <p>
                            Customer satisfaction for us starts with providing
                            top class products at affordable prices that will
                            work trouble free for a long time. Our service staff
                            always .
                          </p>
                        </div>
                      </div>

                      <div
                        className="why-features-item wow fadeInUp"
                        data-wow-delay="0.75s"
                      >
                        <div className="icon-box">
                          <img src="images/icon-checkmark.svg" alt="" />
                        </div>
                        <div className="why-features-desc">
                          <h3>High Quality Work</h3>
                          <p>
                            High-quality solar panels are engineered to capture
                            and convert sunlight into electricity with optimal
                            efficiency.
                          </p>
                        </div>
                      </div>

                      <div
                        className="why-features-item wow fadeInUp"
                        data-wow-delay="1s"
                      >
                        <div className="icon-box">
                          <img src="images/icon-checkmark.svg" alt="" />
                        </div>
                        <div className="why-features-desc">
                          <h3>Efficiency & Power</h3>
                          <p>
                            The sun provides more than enough energy to meet the
                            whole world's energy needs, and unlike fossil fuels,
                            it won't run out anytime soon.
                          </p>
                        </div>
                      </div>
                      <div
                        className="why-features-item wow fadeInUp"
                        data-wow-delay="1s"
                      >
                        <div className="icon-box">
                          <img src="images/icon-checkmark.svg" alt="" />
                        </div>
                        <div className="why-features-desc">
                          <h3>Trust & Warranty</h3>
                          <p>
                            A solar panel manufacturer may provide a product or
                            performance warranty of 25 years that will cover a
                            replacement panel should you need it.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="our-process-layout2">
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <div className="section-title">
                    <h3 className="wow fadeInUp">Our Process</h3>
                    <h2
                      className="text-anime"
                      data-aos="fade-right"
                      data-aos-offset="300"
                      data-wow-duration="1000ms"
                      data-aos-easing="ease-in-sine"
                    >
                      Our Work Process
                    </h2>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-4 mb-4">
                  <div
                    className="process-item2 wow fadeInUp"
                    data-wow-delay="0.25s"
                  >
                    <div className="process-step">
                      <span>01</span>
                    </div>
                    <div className="process-content">
                      <div className="icon-box">
                        <img src="images/icon-process-1.svg" alt="" />
                      </div>
                      <h3>Project Planing</h3>
                      <p>
                        There are typically five project life cycle phases:
                        initiation, planning, execution, monitoring and
                        controlling, and closure.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div
                    className="process-item2 wow fadeInUp"
                    data-wow-delay="0.5s"
                  >
                    <div className="process-step">
                      <span>02</span>
                    </div>
                    <div className="process-content">
                      <div className="icon-box">
                        <img src="images/icon-process-2.svg" alt="" />
                      </div>
                      <h3>Research &amp; Analysis</h3>
                      <p>
                        Sri Balaji research covers photovoltaics, concentrating
                        solar power, solar grid and systems integration, and
                        market research and analysis.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div
                    className="process-item2 wow fadeInUp"
                    data-wow-delay="0.75s"
                  >
                    <div className="process-step">
                      <span>03</span>
                    </div>
                    <div className="process-content">
                      <div className="icon-box">
                        <img src="images/icon-process-3.svg" alt="" />
                      </div>
                      <h3>Solar Installation</h3>
                      <p>
                        Your solar installer preps your roof and ensures the
                        tiles are correctly attached. then install electrical
                        wiring that connects the solar panels to the electric
                        panel
                        <br /> inside of it.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="latest-post-layout2">
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <div className="section-title">
                    <h3 className="wow fadeInUp">latest project</h3>
                    <h2 className="text-anime">Our Latest Work</h2>
                  </div>
                </div>
              </div>
              <div className="row">
                {/* <Swiper

                  centeredSlides={true}
                  coverflowEffect={{
                    rotate: 50,
                    stretch: 0,
                    depth: 100,
                    modifier: 1,
                    slideShadows: false,
                  }}
                  spaceBetween={30}
                  autoplay={{
                    delay: 2500,
                  }}
                  loop={true}
                  slidesPerView={3}
                  modules={[Autoplay, Pagination, EffectCoverflow]}
                  className="mySwiper"
                  breakpoints={{
                    0: {
                      slidesPerView: 1,
                    },
                    400: {
                      slidesPerView: 1,
                    },
                    639: {
                      slidesPerView: 1,
                    },
                    865: {
                      slidesPerView: 3
                    },
                    1000: {
                      slidesPerView: 3
                    },
                    1500: {
                      slidesPerView: 3
                    },
                    1700: {
                      slidesPerView: 3
                    }
                  }}
                >
                  <SwiperSlide>
                    <div className="col-lg-4 ">

                      <div className="post-item2 wow fadeInUp" data-wow-delay="0.2s">
                        <div className="post-featured-image">
                          <figure className="image-anime">
                            <img src="images/tallaimg.jpg" alt="" />
                          </figure>

                        </div>
                        <div className="post-body">
                          <h2>Talla Padmavathi 56 kw</h2>

                        </div>
                      </div>

                    </div>
                  </SwiperSlide>

                  <SwiperSlide>
                    <div className="col-lg-4">

                      <div className="post-item2 wow fadeInUp" data-wow-delay="0.4s">
                        <div className="post-featured-image">
                          <figure className="image-anime">
                            <img src={relief} alt="" />
                          </figure>

                        </div>
                        <div className="post-body">
                          <h2>Relief hospital arepally 54kw </h2>
                        </div>
                      </div>

                    </div>
                  </SwiperSlide>

                  <SwiperSlide>
                    <div className="col-lg-4 mb-3">

                      <div className="post-item2 wow fadeInUp" data-wow-delay="0.6s">
                        <div className="post-featured-image">
                          <figure className="image-anime">
                            <img src="images/about-1.jpg" alt="" />
                          </figure>

                        </div>
                        <div className="post-body">
                          <h2>Vagdevi junior college 30 kw </h2>

                        </div>
                      </div>

                    </div>
                  </SwiperSlide>

                  ...
                </Swiper> */}

                <div className="col-lg-4 mb-3">
                  <div
                    className="post-item2 wow fadeInUp"
                    data-wow-delay="0.2s"
                  >
                    <div className="post-featured-image">
                      <figure className="image-anime">
                        <img
                          src="images/about-5.jpg"
                          alt=""
                          data-bs-toggle="modal"
                          data-bs-target="#imageExample"
                        />
                      </figure>
                      {/* <div className='modal fade' id='imageExample' tabIndex="-1" area-hidden="true">
                        <div className='model-dialoge'></div>

                      </div> */}
                    </div>
                    <div className="post-body">
                      <h2>Collector Office Suryapet</h2>
                      <a href="/Projects" class="btn-default">
                        Read More
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div
                    className="post-item2 wow fadeInUp"
                    data-wow-delay="0.4s"
                  >
                    <div className="post-featured-image">
                      <figure className="image-anime">
                        <img src={relief} alt="" />
                      </figure>
                    </div>
                    <div className="post-body">
                      <h2>Relief hospital arepally 54kw </h2>
                      <a href="/Projects" class="btn-default">
                        Read More
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 mb-3">
                  <div
                    className="post-item2 wow fadeInUp"
                    data-wow-delay="0.6s"
                  >
                    <div className="post-featured-image">
                      <figure className="image-anime">
                        <img src="images/about-4.jpg" alt="" />
                      </figure>
                    </div>
                    <div className="post-body">
                      <h2>Vagdevi junior college 30 kw </h2>
                      <a href="/Projects" class="btn-default">
                        Read More
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="company-overview">
            <div className="container">
              <div className="row align-items-center">
                <div className="col-lg-5">
                  <div className="section-title">
                    <h3 className="wow fadeInUp">Our Company</h3>
                    <h2 className="text-anime">Save Electricity Bill</h2>
                  </div>
                  <div
                    className="company-overview-content wow fadeInUp"
                    data-wow-delay="0.25s"
                  >
                    <p>
                      Sri Balaji solar Energies Solar Rooftops offers a
                      cutting-edge solution for sustainable energy,
                      significantly reducing electricity expenses by up to 90%.
                      By harnessing the power of solar technology, businesses
                      can achieve substantial cost savings while contributing to
                      a greener and more environmentally conscious future.
                    </p>
                    <a href="/contact" className="btn-default">
                      Contact Now
                    </a>
                  </div>
                </div>
                <div className="col-lg-7">
                  <div className="overview-counter-box">
                    <div className="row">
                      <div className="col-md-6">
                        <div
                          className="overview-counter-item wow fadeInUp"
                          data-wow-delay="0.25s"
                        >
                          <div className="icon-box">
                            <img src="images/icon-project.svg" alt="" />
                          </div>
                          <div className="overview-counter-content">
                            <h3>
                              <span className="counter">
                                <CountUp delay={2} end={1000} />
                              </span>
                              +
                            </h3>
                            <p>Project Done</p>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div
                          className="overview-counter-item wow fadeInUp"
                          data-wow-delay="0.4s"
                        >
                          <div className="icon-box">
                            <img src="images/icon-happy-clients.svg" alt="" />
                          </div>
                          <div className="overview-counter-content">
                            <h3>
                              <span className="counter">
                                <CountUp delay={2} end={500} />
                              </span>
                              +
                            </h3>
                            <p>Happy Clients</p>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div
                          className="overview-counter-item wow fadeInUp"
                          data-wow-delay="0.6s"
                        >
                          <div className="icon-box">
                            <img src="images/icon-award.svg" alt="" />
                          </div>
                          <div className="overview-counter-content">
                            <h3>
                              <span className="counter">
                                <CountUp delay={2} end={12} />
                              </span>
                              +
                            </h3>
                            <p>Award Winnings</p>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div
                          className="overview-counter-item wow fadeInUp"
                          data-wow-delay="0.8s"
                        >
                          <div className="icon-box">
                            <img src="images/icon-ratting.svg" alt="" />
                          </div>
                          <div className="overview-counter-content">
                            <h3>
                              <span className="counter">
                                <CountUp delay={2} end={1000} />
                              </span>
                              +
                            </h3>
                            <p>Rating Customer</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="testimonial-layout2">
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <div className="section-title">
                    <h3 className="wow fadeInUp">Our Testimonials</h3>
                    <h2 className="text-anime">
                      See What People Say's About Us
                    </h2>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <div className="testimonial-slider">
                    <div className="swiper">
                      <div className="swiper-wrapper">
                        <Swiper
                          centeredSlides={true}
                          coverflowEffect={{
                            rotate: 50,
                            stretch: 0,
                            depth: 100,
                            modifier: 1,
                            slideShadows: false,
                          }}
                          spaceBetween={30}
                          autoplay={{
                            delay: 2500,
                          }}
                          loop={true}
                          slidesPerView={3}
                          modules={[Autoplay, Pagination, EffectCoverflow]}
                          className="mySwiper"
                          breakpoints={{
                            0: {
                              slidesPerView: 1,
                            },
                            400: {
                              slidesPerView: 1,
                            },
                            639: {
                              slidesPerView: 1,
                            },
                            865: {
                              slidesPerView: 3,
                            },
                            1000: {
                              slidesPerView: 3,
                            },
                            1500: {
                              slidesPerView: 3,
                            },
                            1700: {
                              slidesPerView: 3,
                            },
                          }}
                        >
                          <SwiperSlide>
                            <div className="swiper-slide">
                              <div className="testimonial-item">
                                <div className="testimonial-header">
                                  <div className="testimonial-author-img">
                                    <figure className="image-anime">
                                      <img src={icon} alt="" />
                                    </figure>
                                    <div className="icon-quote">
                                      <img src="images/icon-quote.svg" alt="" />
                                    </div>
                                  </div>
                                  <h2>mahender reddy pundra</h2>
                                  <p>(Customer)</p>
                                  <div className="testimonial-rating">
                                    <img src="images/icon-star.svg" alt="" />
                                  </div>
                                </div>
                                <div className="testimonial-body">
                                  <p>
                                    No power bill after taken 3kw before monthly
                                    getting 3500 to 4200 now only power bill 278
                                    thanks.one time investment Rs.140000.
                                  </p>
                                </div>
                              </div>
                            </div>
                          </SwiperSlide>
                          <SwiperSlide>
                            <div className="swiper-slide">
                              <div className="testimonial-item">
                                <div className="testimonial-header">
                                  <div className="testimonial-author-img">
                                    <figure className="image-anime">
                                      <img src={iconwomen} alt="" />
                                    </figure>
                                    <div className="icon-quote">
                                      <img src="images/icon-quote.svg" alt="" />
                                    </div>
                                  </div>
                                  <h2>Sai</h2>
                                  <p>(Customer)</p>
                                  <div className="testimonial-rating">
                                    <img src="images/icon-star.svg" alt="" />
                                  </div>
                                </div>
                                <div className="testimonial-body">
                                  <p>
                                    Ongrid solar super for home because current
                                    bill is reduced to lot Thanks to Sri Balaji
                                    solar for giving solar plant in subsidy
                                  </p>
                                </div>
                              </div>
                            </div>
                          </SwiperSlide>
                          <SwiperSlide>
                            <div className="swiper-slide">
                              <div className="testimonial-item">
                                <div className="testimonial-header">
                                  <div className="testimonial-author-img">
                                    <figure className="image-anime">
                                      <img src={icon} alt="" />
                                    </figure>
                                    <div className="icon-quote">
                                      <img src="images/icon-quote.svg" alt="" />
                                    </div>
                                  </div>
                                  <h2>Yesh</h2>
                                  <p>(Customer)</p>
                                  <div className="testimonial-rating">
                                    <img src="images/icon-star.svg" alt="" />
                                  </div>
                                </div>
                                <div className="testimonial-body">
                                  <p>
                                    Solar is very important present generation
                                    for reducing power bill with solar ongrid
                                    system. previously we got 3000 to 3800 but
                                    after taken solar there is only 278 power
                                    bill coming thanks to solar company.
                                  </p>
                                </div>
                              </div>
                            </div>
                          </SwiperSlide>
                          <SwiperSlide>
                            <div className="swiper-slide">
                              <div className="testimonial-item">
                                <div className="testimonial-header">
                                  <div className="testimonial-author-img">
                                    <figure className="image-anime">
                                      <img src={iconwomen} alt="" />
                                    </figure>
                                    <div className="icon-quote">
                                      <img src="images/icon-quote.svg" alt="" />
                                    </div>
                                  </div>
                                  <h2>Swarna</h2>
                                  <p>(Customer)</p>
                                  <div className="testimonial-rating">
                                    <img src="images/icon-star.svg" alt="" />
                                  </div>
                                </div>
                                <div className="testimonial-body">
                                  <p>
                                    Nice team i contact lots members for my
                                    solar system no one not responding finally
                                    in warangal local we find SRI BALAJI SOLAR
                                    they are doing good service within the 3
                                    hours our solar system worked as a new
                                    thanks Balaji team.
                                  </p>
                                </div>
                              </div>
                            </div>
                          </SwiperSlide>
                          ...
                        </Swiper>
                      </div>
                      <div className="swiper-pagination" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <Footer />
        </>
      )}
    </div>
  );
}

export default Home;
