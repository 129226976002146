import React, { useRef, useState, useEffect } from 'react'

import loadernew from '../images/loadernew.gif';
import Header from './Header'
import Footer from './Footer'
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination, Navigation, EffectCoverflow } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/effect-coverflow';
import 'swiper/css/navigation';
import icon from '../images/icon.jpg';
import iconwomen from '../images/iconwomen.jpg';
function Testimonials() {
  const [isLoading, setIsLoading] = useState(true)

  const vidRef = useRef();
  const loadimg = () => {
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  }
  useEffect(() => {

    loadimg();
  }, []);
  return (
    <div>
      {isLoading == true ? (

        <>
          <div
            style={{ zIndex: "9999999999999", height: "420px", }}
            className="text-center mt-3 mb-5"
          >
            <img src={loadernew} height="140px" style={{ marginTop: '250px' }}></img>

          </div>
        </>
      ) : (
        <>
          <Header />
          <div className="page-header parallaxie">
            <div className="container">
              <div className="row">
                <div className="col-md-12">

                  <div className="page-header-box">
                    <h1 className="text-anime">Testimonials</h1>
                    <nav className="wow fadeInUp" data-wow-delay="0.25s">
                      <ol className="breadcrumb">
                        <li className="breadcrumb-item"><a href="/">Home</a></li>

                        <li className="breadcrumb-item active" aria-current="page">Testimonials</li>
                      </ol>
                    </nav>
                  </div>

                </div>
              </div>
            </div>
          </div>
          <div className="testimonials mt-5">
            <div className="container">
              <div className="row">
                <div className="col-md-12">

                  <div className="section-title">
                    <h3 className="wow fadeInUp">Testimonials</h3>
                    <h2 className="text-anime">Words From Our Customer</h2>
                  </div>

                </div>
              </div>
              <div className="row">
                <div className="col-md-12">

                  <div className="testimonial-slider">
                    <div className="swiper">
                      <div className="swiper-wrapper">
                      <Swiper

centeredSlides={true}
coverflowEffect={{
  rotate: 50,
  stretch: 0,
  depth: 100,
  modifier: 1,
  slideShadows: false,
}}
spaceBetween={30}
autoplay={{
  delay: 2500,
}}
loop={true}
slidesPerView={3}
modules={[Autoplay, Pagination, EffectCoverflow]}
className="mySwiper"
breakpoints={{
  0: {
    slidesPerView: 1,
  },
  400: {
    slidesPerView: 1,
  },
  639: {
    slidesPerView: 1,
  },
  865: {
    slidesPerView: 3
  },
  1000: {
    slidesPerView: 3
  },
  1500: {
    slidesPerView: 3
  },
  1700: {
    slidesPerView: 3
  }
}}
>


<SwiperSlide>
  <div className="swiper-slide">
    <div className="testimonial-item">
      <div className="testimonial-header">
        <div className="testimonial-author-img">
          <figure className="image-anime">
            <img src={icon} alt="" />
          </figure>
          <div className="icon-quote">
            <img src="images/icon-quote.svg" alt="" />
          </div>
        </div>
        <h2>mahender reddy pundra</h2>
        <p>(Customer)</p>
        <div className="testimonial-rating">
          <img src="images/icon-star.svg" alt="" />
        </div>
      </div>
      <div className="testimonial-body">
        <p>No power bill after taken 3kw before monthly getting 3500 to 4200 now only power bill 278 thanks.one time investment Rs.140000.</p>
      </div>
    </div>
  </div>
</SwiperSlide>

<SwiperSlide>
  <div className="swiper-slide">
    <div className="testimonial-item">
      <div className="testimonial-header">
        <div className="testimonial-author-img">
          <figure className="image-anime">
            <img src={iconwomen} alt="" />
          </figure>
          <div className="icon-quote">
            <img src="images/icon-quote.svg" alt="" />
          </div>
        </div>
        <h2>Sai</h2>
        <p>(Customer)</p>
        <div className="testimonial-rating">
          <img src="images/icon-star.svg" alt="" />
        </div>
      </div>
      <div className="testimonial-body">
        <p>Ongrid solar super for home because current bill is reduced to lot Thanks to Sri Balaji solar for giving solar plant in subsidy</p>
      </div>
    </div>
  </div>
</SwiperSlide>

<SwiperSlide>
  <div className="swiper-slide">
    <div className="testimonial-item">
      <div className="testimonial-header">
        <div className="testimonial-author-img">
          <figure className="image-anime">
            <img src={icon} alt="" />
          </figure>
          <div className="icon-quote">
            <img src="images/icon-quote.svg" alt="" />
          </div>
        </div>
        <h2>Yesh</h2>
        <p>(Customer)</p>
        <div className="testimonial-rating">
          <img src="images/icon-star.svg" alt="" />
        </div>
      </div>
      <div className="testimonial-body">
        <p>Solar is very important present generation for reducing power bill with solar ongrid system. previously we got 3000 to 3800 but after taken solar there is only 278 power bill coming thanks to solar company.</p>
      </div>
    </div>
  </div>
</SwiperSlide>

<SwiperSlide>
  <div className="swiper-slide">
    <div className="testimonial-item">
      <div className="testimonial-header">
        <div className="testimonial-author-img">
          <figure className="image-anime">
            <img src={iconwomen} alt="" />
          </figure>
          <div className="icon-quote">
            <img src="images/icon-quote.svg" alt="" />
          </div>
        </div>
        <h2>Swarna</h2>
        <p>(Customer)</p>
        <div className="testimonial-rating">
          <img src="images/icon-star.svg" alt="" />
        </div>
      </div>
      <div className="testimonial-body">
        <p>Nice team i contact lots members for my solar system no one not responding finally in warangal local we find SRI BALAJI SOLAR they are doing good service within the 3 hours our solar system worked as a new thanks Balaji team.</p>
      </div>
    </div>
  </div>
</SwiperSlide>
...
</Swiper>

                      </div>
                      <div className="swiper-pagination" />
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
          <Footer />
        </>
      )}
    </div>
  )
}

export default Testimonials