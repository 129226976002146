import React,{useRef,useState,useEffect} from 'react'

import loadernew from '../images/loadernew.gif';
import Header from './Header'
import Footer from './Footer'
import solarled from '../images/Products/solarled.jpg';
function Solarstreetlights() {
  const [isLoading, setIsLoading] = useState(true)

  const vidRef = useRef();
  const loadimg = () => {
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  }
  useEffect(() => {

    loadimg();
  }, []);
  return (
    <div>
       {isLoading == true ? (

<>
  <div
    style={{ zIndex: "9999999999999", height: "420px", }}
    className="text-center mt-3 mb-5"
  >
    <img src={loadernew} height="140px" style={{marginTop:'250px'}}></img>

  </div>
</>
) : (
<>
      <Header />
      <div className="page-header parallaxie">
        <div className="container">
          <div className="row">
            <div className="col-md-12">

              <div className="page-header-box">
                <h1 className="text-anime">Solar street lights</h1>
                <div className="post-single-meta wow fadeInUp" data-wow-delay="0.25s">
                  <ul>
                    <li><a href="/">Home</a></li>
                    <li>Solar street lights</li>
                  </ul>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
      <div className="page-single-post">
        <div className="container">
          <div className="row">
            <div className="col-md-12">

              <div className="post-featured-image wow fadeInUp" data-wow-delay="0.25s">
                <figure className="image-anime">
                  <img src={solarled} alt="" />
                </figure>
              </div>

              <div className="post-content">
                <div className="post-entry">
                  <p>SRI BALAJI SOLAR ENERGIES initiated to workout the Solar Power Systems with standard a Solar Street Lights are well-designed to illuminate large areas with the highest intensity of light. we offers a wide variety of configurations and styles to meet your specific needs. The annexed table is just an example of what we can supply. The system will be configured according to the environmental conditions of the installation site as well as your specific requirements. we have a wide range of solar street lights from 10 to 180W LED power.</p>
                  <ul>
                    <li>➻ A-grade cells from Yingli solar with efficiency over 17,8 %</li>
                    <li> ➻ Aluminum casing for high resistance </li>
                    <li>➻ IP68 led controller with smart controller included</li>
                    <li> ➻ Cable suitable to resist up to 200°C</li>
                    

                  </ul>
                </div>


              </div>

            </div>
          </div>
        </div>
      </div>
      <div className="service-benefits">
      <div className="row">
          <div className="col-md-12">
            <div className="service-benefits-title">
              <h2 className="text-anime">Advantages</h2>
              <ul>
                Solar street lights require lesser maintenance than conventional street lights. These have lower chances of overheating. Since solar wires do not have external wires, the risk of accidents is minimized.
              Solar street lights are environment-friendly because its panels are solely dependent to the sun hence eliminating your carbon footprints contribution.
              </ul>
              
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="service-benefits-title">
              <h2 className="text-anime">Benefits of Solar Energy</h2>
              
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-4 col-md-6">

            <div className="benefits-item">
              <div className="icon-box">
                <img src="images/icon-benefits-1.svg" alt="" />
              </div>
              <h3>Renewable Energy</h3>
              <p>Renewable energy comes from unlimited, naturally replenished resources, such as the sun, tides, and wind.</p>
            </div>

          </div>
          <div className="col-lg-4 col-md-6">

            <div className="benefits-item">
              <div className="icon-box">
                <img src="images/icon-benefits-2.svg" alt="" />
              </div>
              <h3>Energy Saving</h3>
              <p>Turn off your lights: It will also help in reducing the unnecessary energy consumption.</p>
            </div>

          </div>
          <div className="col-lg-4 col-md-6">

            <div className="benefits-item">
              <div className="icon-box">
                <img src="images/icon-benefits-3.svg" alt="" />
              </div>
              <h3>Easy Installation</h3>
              <p>Home solar panel installations require electrical, roofing, and other skilled work.</p>
            </div>

          </div>
          <div className="col-lg-4 col-md-6">
            <div className="benefits-item">
              <div className="icon-box">
                <img src="images/icon-benefits-4.svg" alt="" />
              </div>
              <h3>Energy Solution</h3>
              <p>The sun provides enough energy in just ONE HOUR to meet our global energy needs for a whole year.</p>
            </div>

          </div>
          <div className="col-lg-4 col-md-6">

            <div className="benefits-item">
              <div className="icon-box">
                <img src="images/icon-benefits-5.svg" alt="" />
              </div>
              <h3>Technical Support</h3>
              <p>There are three primary technologies by which solar energy is harnessed: photovoltaics (PV), which directly convert light to electricity.</p>
            </div>

          </div>
          <div className="col-lg-4 col-md-6">

            <div className="benefits-item">
              <div className="icon-box">
                <img src="images/icon-benefits-6.svg" alt="" />
              </div>
              <h3>Solar Maintenance</h3>
              <p>Solar companies recommend cleaning your solar panels at least twice a year, regardless of the weather conditions and where you live.</p>
            </div>

          </div>
        </div>
      </div>
      <Footer />
      </>
)}
    </div>
  )
}

export default Solarstreetlights