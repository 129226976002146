import React from 'react'
import Header from './Header'
import fb from '../images/links/fb.png';
import twitter from '../images/links/twitter.png';
import insta from '../images/links/insta.png';
import instagram from '../images/links/instagram.png';
import play from '../images/links/you.png';

function Sidebar() {
  return (
    <div>
       <div class="menu-container">
        <div class="collapse-icon">
          <span class="social_media_text"></span>
          
        </div>
        <div class="menu-item">
          <a href="https://www.facebook.com/sribalajisolarenergies" target="_blank" style={{padding: "0px"}}>
            <img src={fb} alt="" />
           
          </a>
        </div>
        <div class="menu-item">
          <a href="https://twitter.com/SriBalajiSolar_" target="_blank" style={{padding: "0px"}}>
            <img src={twitter} alt="" />
           
          </a>
        </div>
        <div class="menu-item">
          <a href="https://www.linkedin.com/in/sri-balaji-solar-energies-45b841304/" target="_blank" style={{padding: "0px"}}>
            <img src={insta} alt="" />
          
          </a>
        </div>
        <div class="menu-item">
          <a href="https://www.instagram.com/sri_balaji_solar_energies/" target="_blank" style={{padding: "0px"}}>
            <img src={instagram} alt="" />
         
          </a>
        </div>
        <div class="menu-item">
          <a href="https://www.youtube.com/@sribalajisolarenergies6619/" target="_blank" style={{padding: "0px"}}>
            <img src={play} alt="" style={{height:"20px"}} />
           
          </a>
        </div>
      </div>
    </div>
  )
}

export default Sidebar