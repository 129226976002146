import React from 'react'

function Whatsapp() {
  return (
    <div>
         <div style={{ float: "right" }}>
        <div className='menu-container1'>
        
          <a href="https://web.whatsapp.com/send?phone=919515954923&amp;text=" target="_blank" class="sbutton whatsapp" tooltip="WhatsApp">
          <i class="fa-brands fa-whatsapp" id='whatsappicon' aria-hidden="true"></i>
            </a>
        </div>
      </div>
    </div>
  )
}

export default Whatsapp