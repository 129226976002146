import React, { useRef, useState, useEffect } from 'react'

import loadernew from '../images/loadernew.gif';
import Header from './Header'
import Footer from './Footer'

function Contact() {
  const [isLoading, setIsLoading] = useState(true)

  const vidRef = useRef();
  const loadimg = () => {
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  }
  useEffect(() => {

    loadimg();
  }, []);
  return (
    <div>
       {isLoading == true ? (

<>
  <div
    style={{ zIndex: "9999999999999", height: "420px", }}
    className="text-center mt-3 mb-5"
  >
    <img src={loadernew} height="140px" style={{ marginTop: '250px' }}></img>

  </div>
</>
) : (
<>
      <Header />
      <div className="page-header parallaxie">
        <div className="container">
          <div className="row">
            <div className="col-md-12">

              <div className="page-header-box">
                <h1 className="text-anime">Contact us</h1>
                <nav className="wow fadeInUp" data-wow-delay="0.25s">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item"><a href="/">Home</a></li>
                    <li className="breadcrumb-item active" aria-current="page">Contact us</li>
                  </ol>
                </nav>
              </div>

            </div>
          </div>
        </div>
      </div>
      <div className="contact-information">
        <div className="container">
          <div className="row">
            <div className="col-md-12">

              <div className="section-title">
                <h3 className="wow fadeInUp">Contact Details</h3>
                <h2 className="text-anime">Happy to Answer All Your Questions</h2>
              </div>

            </div>
          </div>
          <div className="row">
            <div className="col-lg-3 col-md-6">

              <div className="contact-info-item wow fadeInUp" data-wow-delay="0.25s">
                <div className="contact-image">
                  <figure className="image-anime">
                    <img src="images/location-img.jpg" alt="" />
                  </figure>
                </div>
                <div className="contact-info-content">
                  <h3>Our Address:</h3>
                  <p>Hyderabad</p>
                </div>
                <div className="contact-icon">
                  <img src="images/icon-location.svg" alt="" />
                </div>
              </div>

            </div>
            <div className="col-lg-3 col-md-6">

              <div className="contact-info-item wow fadeInUp" data-wow-delay="0.5s">
                <div className="contact-image">
                  <figure className="image-anime">
                    <img src="images/email-img.jpg" alt="" />
                  </figure>
                </div>
                <div className="contact-info-content">
                  <h3>Email Us:</h3>
                  <p><a href='mailto: sbse4444@gmail.com'>sbse4444@gmail.com</a></p>
                </div>
                <div className="contact-icon">
                  <img src="images/icon-mail.svg" alt="" />
                </div>
              </div>

            </div>
            <div className="col-lg-3 col-md-6">

              <div className="contact-info-item wow fadeInUp" data-wow-delay="0.75s">
                <div className="contact-image">
                  <figure className="image-anime">
                    <img src="images/phone-img.jpg" alt="" />
                  </figure>
                </div>
                <div className="contact-info-content">
                  <h3>Phone:</h3>
                  <p> <a href="tel:+ 91 951 595 49 23">  +91 903 204 1774</a></p>
                </div>
                <div className="contact-icon">
                  <img src="images/icon-phone.svg" alt="" />
                </div>
              </div>

            </div>
            <div className="col-lg-3 col-md-6">

              <div className="contact-info-item wow fadeInUp" data-wow-delay="1.0s">
                <div className="contact-image">
                  <figure className="image-anime">
                    <img src="images/follow-img.jpg" alt="" />
                  </figure>
                </div>
                <div className="contact-info-content">
                  <h3>Follow Us:</h3>
                  <ul>
                    <li><a href="https://www.facebook.com/sribalajisolarenergies" target='blank'><i className="fa-brands fa-facebook-f" /></a></li>
                    <li><a href="https://twitter.com/SriBalajiSolar_" target='blank'><i className="fa-brands fa-twitter" /></a></li>
                    <li><a href="https://www.linkedin.com/uas/login?session_redirect=https%3A%2F%2Fwww.linkedin.com%2Ffeed%2F" target='blank'><i className="fa-brands fa-linkedin-in" /></a></li>
                    <li><a href="https://www.instagram.com/sri_balaji_solar_energies/" target='blank'><i className="fa-brands fa-instagram" /></a></li>
                  </ul>
                </div>
                <div className="contact-icon">
                  <img src="images/icon-follow.svg" alt="" />
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
      <div className="google-map-form">
        <div className="google-map">
          <iframe src="https://maps.google.com/maps?q=6-1-34%2C1st+floor+Chinthala+Narasimhulu+complex+Near+Mission+Hospital%2CLashkar+Bazar%2C+Hanamkonda+SRI+BALAJI+SOLAR+ENERGIES+%28SBSE%29%2C+Warangal%2C+Telangana+506001&t=&z=13&ie=UTF8&iwloc=&output=embed" />
          
        </div>
        <div className="container">
          <div className="row">
            <div className="col-lg-6 offset-lg-6">
              <div className="contact-form-box">

                <div className="section-title">
                  <h3 className="wow fadeInUp">Contact Now</h3>
                  <h2 className="text-anime">Get In Touch With Us</h2>
                </div>

                <div className="contact-form wow fadeInUp" data-wow-delay="0.75s">
                  <form id="contactForm" action="#" method="POST" data-toggle="validator">
                    <div className="row">
                      <div className="form-group col-md-6 mb-4">
                        <input type="text" name="name" className="form-control" id="name" placeholder="Name" required />
                        <div className="help-block with-errors" />
                      </div>
                      <div className="form-group col-md-6 mb-4">
                        <input type="email" name="email" className="form-control" id="email" placeholder="Email" required />
                        <div className="help-block with-errors" />
                      </div>
                      <div className="form-group col-md-6 mb-4">
                        <input type="text" name="phone" className="form-control" id="phone" placeholder="Phone" required />
                        <div className="help-block with-errors" />
                      </div>
                      <div className="form-group col-md-6 mb-4">
                        <input type="text" name="subject" className="form-control" id="subject" placeholder="Subject" required />
                        <div className="help-block with-errors" />
                      </div>
                      <div className="form-group col-md-12 mb-4">
                        <textarea name="msg" className="form-control" id="msg" rows={4} placeholder="Write a Message" required defaultValue={""} />
                        <div className="help-block with-errors" />
                      </div>
                      <div className="col-md-12 text-center">
                        <button type="submit" className="btn-default">Submit Now</button>
                        <div id="msgSubmit" className="h3 text-left hidden" />
                      </div>
                    </div>
                  </form>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
      </>
)}
    </div>
  )
}

export default Contact