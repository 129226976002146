
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from './Components/Home';
import Aboutus from './Components/Aboutus';
import Products from "./Components/Products";
import Solarrooftops from "./Components/Solarrooftops";
import Industrialrooftops from "./Components/Induatrialrooftops";
import Commercialrooftops from "./Components/Commercialrooftops";
import ResidentialRooftops from "./Components/Residencialrooftops";
import Ongridconnectedsolarpvsysytems from './Components/Ongridconnectedsolarpvsysytems';
import Offgridconnectedsolarpvsysytems from './Components/Offgridconnectedsolarpvsysytems';
import Solarwaterpumpingsystem from './Components/Solarwaterpumpingsystem';
import Solarfencingsystem from './Components/Solarfencingsystem';
import Ledstreetlights from './Components/Ledstreetlights';
import Solarstreetlights from './Components/Solarstreetlights'
import Solarwaterheaters from "./Components/Solarwaterheaters";
import Testimonials from "./Components/Testimonials";
import Termsandconditions from "./Components/Termsandconditions";
import Privacupolicy from "./Components/Privacupolicy";
import Project from "./Components/Project";
import Awards from "./Components/Awards";
import Clients from "./Components/Clients";
import Gallary from "./Components/Gallary";
import Contact from "./Components/Contact";
import Sidebar from "./Components/Sidebar";
import Clientdetails1 from "./Components/Clientdetails1";
import Clientdetails2 from "./Components/Clientdetails2";
import Clientdetails3 from "./Components/Clientdetails3";
function App() {
  return (
    <div>
       <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<Aboutus />} />
          <Route path="/solar-rooftops" element={<Solarrooftops />} />
          <Route path="/products" element={<Products />} />
          <Route path="/commercial-rooftops" element={<Commercialrooftops />} />
          <Route path="/industrial-rooftops" element={<Industrialrooftops />} />
          <Route path="/Residential-Rooftops" element={<ResidentialRooftops />} />
          <Route path="/On-Grid-Connected-Solar-Pvsystems" element={<Ongridconnectedsolarpvsysytems />} />
          <Route path="/Off-Grid-Connected-Solar-Pvsystems" element={<Offgridconnectedsolarpvsysytems />} />
          <Route path="/SolarWaterPumpingSystem" element={<Solarwaterpumpingsystem />} />
          <Route path="/SolarFencingSystem" element={<Solarfencingsystem />} />
          <Route path="/LedStreetLights" element={<Ledstreetlights />} />
          <Route path="/SolarStreetLights" element={<Solarstreetlights/>} />
          <Route path="/solar-waterheater" element={<Solarwaterheaters />} />
          <Route path="/testimonials" element={<Testimonials />} />
          <Route path="/termsandcontions" element={<Termsandconditions />} />
          <Route path="/privacypolicy" element={<Privacupolicy />} />
          <Route path="/projects" element={<Project />} />
          <Route path="/awards" element={<Awards />} />
          <Route path="/Clients" element={<Clients/>} />
          <Route path="/gallery" element={<Gallary />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/Sidebar" element={<Sidebar />} />
          <Route path="/Clientdetails1" element={<Clientdetails1 />} />
          <Route path="/Clientdetails2" element={<Clientdetails2 />} />
          <Route path="/Clientdetails3" element={<Clientdetails3 />} />
          
          
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
