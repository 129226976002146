import React from 'react'
import Header from './Header'
import Footer from './Footer'

function Privacupolicy() {
  return (
    <div>
      <Header/>
       <div className="page-header parallaxie">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
            
              <div className="page-header-box">
                <h1 className="text-anime">Privacy policy</h1>
                <div className="post-single-meta wow fadeInUp" data-wow-delay="0.25s">
                  <ul>
                  <li><a href="/">Home</a></li>
                    <li>Privacy policy</li>
                  </ul>
                </div>
              </div>
             
            </div>
          </div>
        </div>
      </div>
      <Footer/>
    </div>
  )
}

export default Privacupolicy