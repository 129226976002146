import React, { useRef, useState, useEffect } from "react";

import loadernew from "../images/loadernew.gif";
import Header from "./Header";
import Footer from "./Footer";
import Pic1 from "../images/Gallery/Pic1.png";
import Pic2 from "../images/Gallery/Pic2.png";
import Pic3 from "../images/Gallery/Pic3.png";
import Pic4 from "../images/Gallery/Pic4.png";
import Pic5 from "../images/Gallery/Pic5.png";
import Pic6 from "../images/Gallery/Pic6.png";
import Pic7 from "../images/Gallery/Pic7.png";
import Pic8 from "../images/Gallery/Pic8.png";
import Pic9 from "../images/Gallery/Pic9.png";
import Pic10 from "../images/Gallery/Pic10.png";
import Pic11 from "../images/Gallery/Pic11.png";
import Pic12 from "../images/Gallery/Pic12.png";
import Pic13 from "../images/Gallery/Pic13.png";
import Pic14 from "../images/Gallery/Pic14.png";
import Pic15 from "../images/Gallery/Pic15.png";
import Pic16 from "../images/Gallery/Pic16.png";
import Pic17 from "../images/Gallery/Pic17.png";
import Pic18 from "../images/Gallery/Pic18.png";
import Pic19 from "../images/Gallery/Pic19.png";
import Pic20 from "../images/Gallery/Pic20.png";
import Pic21 from "../images/Gallery/Pic21.png";
import proj1 from "../images/project/proj1.JPG";
import proj2 from "../images/project/proj2.JPG";
import img1 from "../images/project/img1.jpg";
import img2 from "../images/project/img2.jpg";
import img3 from "../images/project/img3.jpg";
import img4 from "../images/project/img4.jpg";
import img5 from "../images/project/img5.jpg";
import img6 from "../images/project/img6.jpg";
import img7 from "../images/project/img7.jpg";
import img8 from "../images/project/img8.jpg";
import img9 from "../images/project/img9.jpg";
import img10 from "../images/project/img10.jpg";
import img11 from "../images/project/img11.jpg";
import img12 from "../images/project/img12.jpg";

import img13 from "../images/a1.jpg";
import img14 from "../images/a2.jpg";
import img15 from "../images/a3.jpg";
import img16 from "../images/a4.jpg";

function Project() {
  const [isLoading, setIsLoading] = useState(true);

  const vidRef = useRef();
  const loadimg = () => {
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  };
  useEffect(() => {
    loadimg();
  }, []);
  return (
    <div>
      {isLoading == true ? (
        <>
          <div
            style={{ zIndex: "9999999999999", height: "420px" }}
            className="text-center mt-3 mb-5"
          >
            <img
              src={loadernew}
              height="140px"
              style={{ marginTop: "250px" }}
            ></img>
          </div>
        </>
      ) : (
        <>
          <Header />
          <div className="page-header parallaxie">
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  {/* Page Header Box Start */}
                  <div className="page-header-box">
                    <h1 className="text-anime">Our Projects</h1>
                    <nav className="wow fadeInUp" data-wow-delay="0.25s">
                      <ol className="breadcrumb">
                        <li className="breadcrumb-item">
                          <a href="/">Home</a>
                        </li>
                        <li
                          className="breadcrumb-item active"
                          aria-current="page"
                        >
                          Projects
                        </li>
                      </ol>
                    </nav>
                  </div>
                  {/* Page Header Box End */}
                </div>
              </div>
            </div>
          </div>
          <div className="our-projects">
            <div className="container">
              <div className="row">
                <div className="col-lg-3 col-md-6">
                  <div
                    className="project-item wow fadeInUp"
                    data-wow-delay="0.75s"
                  >
                    <div className="project-image">
                      <figure>
                        <img src={proj1} alt="" height="185px" />
                      </figure>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6">
                  <div
                    className="project-item wow fadeInUp"
                    data-wow-delay="0.25s"
                  >
                    <div className="project-image">
                      <figure>
                        <img src={proj2} alt="" height="185px" />
                      </figure>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6">
                  <div
                    className="project-item wow fadeInUp"
                    data-wow-delay="0.5s"
                  >
                    <div className="project-image">
                      <figure>
                        <img src={Pic1} alt="" />
                      </figure>
                    </div>
                    {/* <div className="project-content">
                  <h2><a href="#">LuxSolar Dynamics</a></h2>
                  <p>Wind Energy</p>
                </div> */}
                    {/* <div className="project-link">
                  <a href="#"><img src="images/icon-link.svg" alt="" /></a>
                </div> */}
                  </div>
                </div>
                <div className="col-lg-3 col-md-6">
                  <div
                    className="project-item wow fadeInUp"
                    data-wow-delay="0.75s"
                  >
                    <div className="project-image">
                      <figure>
                        <img src={Pic2} alt="" />
                      </figure>
                    </div>
                    {/* <div className="project-content">
                  <h2><a href="#">HelioHarbor Dynamics</a></h2>
                  <p>Geothermal Energy</p>
                </div> */}
                    {/* <div className="project-link">
                  <a href="#"><img src="images/icon-link.svg" alt="" /></a>
                </div> */}
                  </div>
                </div>
                <div className="col-lg-3 col-md-6">
                  <div
                    className="project-item wow fadeInUp"
                    data-wow-delay="1.0s"
                  >
                    <div className="project-image">
                      <figure>
                        <img src={Pic1} alt="" />
                      </figure>
                    </div>
                    {/* <div className="project-content">
                  <h2><a href="#">SolarLoom Energy</a></h2>
                  <p>Solar Power</p>
                </div> */}
                    {/* <div className="project-link">
                  <a href="#"><img src="images/icon-link.svg" alt="" /></a>
                </div> */}
                  </div>
                </div>
                <div className="col-lg-3 col-md-6">
                  <div
                    className="project-item wow fadeInUp"
                    data-wow-delay="1.25s"
                  >
                    <div className="project-image">
                      <figure>
                        <img src={Pic3} alt="" />
                      </figure>
                    </div>
                    {/* <div className="project-content">
                  <h2><a href="#">HelioHarbor Dynamics</a></h2>
                  <p>Geothermal Energy</p>
                </div> */}
                    {/* <div className="project-link">
                  <a href="#"><img src="images/icon-link.svg" alt="" /></a>
                </div> */}
                  </div>
                </div>
                <div className="col-lg-3 col-md-6">
                  <div
                    className="project-item wow fadeInUp"
                    data-wow-delay="1.5s"
                  >
                    <div className="project-image">
                      <figure>
                        <img src={Pic4} alt="" />
                      </figure>
                    </div>
                    {/* <div className="project-content">
                  <h2><a href="#">SolarLoom Energy</a></h2>
                  <p>Solar Power</p>
                </div> */}
                    {/* <div className="project-link">
                  <a href="#"><img src="images/icon-link.svg" alt="" /></a>
                </div> */}
                  </div>
                </div>
                <div className="col-lg-3 col-md-6">
                  <div
                    className="project-item wow fadeInUp"
                    data-wow-delay="1.75s"
                  >
                    <div className="project-image">
                      <figure>
                        <img src={Pic5} alt="" />
                      </figure>
                    </div>
                    {/* <div className="project-content">
                  <h2><a href="#">EcoHarbor</a></h2>
                  <p>Geothermal Energy</p>
                </div> */}
                    {/* <div className="project-link">
                  <a href="#"><img src="images/icon-link.svg" alt="" /></a>
                </div> */}
                  </div>
                </div>
                <div className="col-lg-3 col-md-6">
                  <div
                    className="project-item wow fadeInUp"
                    data-wow-delay="2.0s"
                  >
                    <div className="project-image">
                      <figure>
                        <img src={Pic6} alt="" />
                      </figure>
                    </div>
                    {/* <div className="project-content">
                  <h2><a href="#">GreenVista Homes</a></h2>
                  <p>Solar Power</p>
                </div> */}
                    {/* <div className="project-link">
                  <a href="#"><img src="images/icon-link.svg" alt="" /></a>
                </div> */}
                  </div>
                </div>
                <div className="col-lg-3 col-md-6">
                  <div
                    className="project-item wow fadeInUp"
                    data-wow-delay="2.0s"
                  >
                    <div className="project-image">
                      <figure>
                        <img src={Pic7} alt="" />
                      </figure>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6">
                  <div
                    className="project-item wow fadeInUp"
                    data-wow-delay="2.0s"
                  >
                    <div className="project-image">
                      <figure>
                        <img src={Pic8} alt="" />
                      </figure>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6">
                  <div
                    className="project-item wow fadeInUp"
                    data-wow-delay="2.0s"
                  >
                    <div className="project-image">
                      <figure>
                        <img src={Pic9} alt="" />
                      </figure>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6">
                  <div
                    className="project-item wow fadeInUp"
                    data-wow-delay="2.0s"
                  >
                    <div className="project-image">
                      <figure>
                        <img src={Pic10} alt="" />
                      </figure>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6">
                  <div
                    className="project-item wow fadeInUp"
                    data-wow-delay="2.0s"
                  >
                    <div className="project-image">
                      <figure>
                        <img src={Pic11} alt="" />
                      </figure>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6">
                  <div
                    className="project-item wow fadeInUp"
                    data-wow-delay="2.0s"
                  >
                    <div className="project-image">
                      <figure>
                        <img src={Pic12} alt="" />
                      </figure>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6">
                  <div
                    className="project-item wow fadeInUp"
                    data-wow-delay="2.0s"
                  >
                    <div className="project-image">
                      <figure>
                        <img src={Pic13} alt="" />
                      </figure>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6">
                  <div
                    className="project-item wow fadeInUp"
                    data-wow-delay="2.0s"
                  >
                    <div className="project-image">
                      <figure>
                        <img src={Pic14} alt="" />
                      </figure>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6">
                  <div
                    className="project-item wow fadeInUp"
                    data-wow-delay="2.0s"
                  >
                    <div className="project-image">
                      <figure>
                        <img src={Pic15} alt="" />
                      </figure>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6">
                  <div
                    className="project-item wow fadeInUp"
                    data-wow-delay="2.0s"
                  >
                    <div className="project-image">
                      <figure>
                        <img src={Pic16} alt="" />
                      </figure>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6">
                  <div
                    className="project-item wow fadeInUp"
                    data-wow-delay="2.0s"
                  >
                    <div className="project-image">
                      <figure>
                        <img src={Pic17} alt="" />
                      </figure>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6">
                  <div
                    className="project-item wow fadeInUp"
                    data-wow-delay="2.0s"
                  >
                    <div className="project-image">
                      <figure>
                        <img src={Pic18} alt="" />
                      </figure>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6">
                  <div
                    className="project-item wow fadeInUp"
                    data-wow-delay="2.0s"
                  >
                    <div className="project-image">
                      <figure>
                        <img src={Pic19} alt="" />
                      </figure>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6">
                  <div
                    className="project-item wow fadeInUp"
                    data-wow-delay="2.0s"
                  >
                    <div className="project-image">
                      <figure>
                        <img src={Pic20} alt="" />
                      </figure>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6">
                  <div
                    className="project-item wow fadeInUp"
                    data-wow-delay="2.0s"
                  >
                    <div className="project-image">
                      <figure>
                        <img src={Pic21} alt="" />
                      </figure>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6">
                  <div
                    className="project-item wow fadeInUp"
                    data-wow-delay="2.0s"
                  >
                    <div className="project-image">
                      <figure>
                        <img src={img1} alt="" />
                      </figure>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6">
                  <div
                    className="project-item wow fadeInUp"
                    data-wow-delay="2.0s"
                  >
                    <div className="project-image">
                      <figure>
                        <img src={img2} alt="" height="195px" />
                      </figure>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6">
                  <div
                    className="project-item wow fadeInUp"
                    data-wow-delay="2.0s"
                  >
                    <div className="project-image">
                      <figure>
                        <img src={img3} alt="" height="195px" />
                      </figure>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6">
                  <div
                    className="project-item wow fadeInUp"
                    data-wow-delay="2.0s"
                  >
                    <div className="project-image">
                      <figure>
                        <img src={img4} alt="" height="195px" />
                      </figure>
                    </div>
                  </div>
                </div>{" "}
                <div className="col-lg-3 col-md-6">
                  <div
                    className="project-item wow fadeInUp"
                    data-wow-delay="2.0s"
                  >
                    <div className="project-image">
                      <figure>
                        <img src={img5} alt="" height="195px" />
                      </figure>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6">
                  <div
                    className="project-item wow fadeInUp"
                    data-wow-delay="2.0s"
                  >
                    <div className="project-image">
                      <figure>
                        <img src={img6} alt="" height="195px" />
                      </figure>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6">
                  <div
                    className="project-item wow fadeInUp"
                    data-wow-delay="2.0s"
                  >
                    <div className="project-image">
                      <figure>
                        <img src={img7} alt="" height="195px" />
                      </figure>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6">
                  <div
                    className="project-item wow fadeInUp"
                    data-wow-delay="2.0s"
                  >
                    <div className="project-image">
                      <figure>
                        <img src={img8} alt="" height="195px" />
                      </figure>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6">
                  <div
                    className="project-item wow fadeInUp"
                    data-wow-delay="2.0s"
                  >
                    <div className="project-image">
                      <figure>
                        <img src={img9} alt="" height="195px" />
                      </figure>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6">
                  <div
                    className="project-item wow fadeInUp"
                    data-wow-delay="2.0s"
                  >
                    <div className="project-image">
                      <figure>
                        <img src={img10} alt="" height="195px" />
                      </figure>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6">
                  <div
                    className="project-item wow fadeInUp"
                    data-wow-delay="2.0s"
                  >
                    <div className="project-image">
                      <figure>
                        <img src={img11} alt="" height="195px" />
                      </figure>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6">
                  <div
                    className="project-item wow fadeInUp"
                    data-wow-delay="2.0s"
                  >
                    <div className="project-image">
                      <figure>
                        <img src={img16} alt="" height="195px" />
                      </figure>
                    </div>
                  </div>
                </div>

                <div className="col-lg-3 col-md-6">
                  <div
                    className="project-item wow fadeInUp"
                    data-wow-delay="2.0s"
                  >
                    <div className="project-image">
                      <figure>
                        <img src={img12} alt="" height="195px" />
                      </figure>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6">
                  <div
                    className="project-item wow fadeInUp"
                    data-wow-delay="2.0s"
                  >
                    <div className="project-image">
                      <figure>
                        <img src={img13} alt="" height="195px" />
                      </figure>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6">
                  <div
                    className="project-item wow fadeInUp"
                    data-wow-delay="2.0s"
                  >
                    <div className="project-image">
                      <figure>
                        <img src={img14} alt="" height="195px" />
                      </figure>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6">
                  <div
                    className="project-item wow fadeInUp"
                    data-wow-delay="2.0s"
                  >
                    <div className="project-image">
                      <figure>
                        <img src={img15} alt="" height="195px" />
                      </figure>
                    </div>
                  </div>
                </div>
                
              </div>
              {/* <div className="row">
            <div className="col-md-12">

              <div className="post-pagination wow fadeInUp" data-wow-delay="1.5s">
                <ul className="pagination">
                  <li><a href="#"><i className="fa-solid fa-arrow-left-long" /></a></li>
                  <li className="active"><a href="#">1</a></li>
                  <li><a href="#">2</a></li>
                  <li><a href="#">3</a></li>
                  <li><a href="#"><i className="fa-solid fa-arrow-right-long" /></a></li>
                </ul>
              </div>

            </div>
          </div> */}
            </div>
          </div>
          {/* <div className="footer-ticker">
        <div className="scrolling-ticker">
          <div className="scrolling-ticker-box">
            <div className="scrolling-content">
              <span>Generate Your Own Power</span>
              <span>Reap the Returns</span>
              <span>Heal the World</span>
              <span>Efficiency &amp; Power</span>
              <span>24*7 Support</span>
            </div>
            <div className="scrolling-content">
              <span>Generate Your Own Power</span>
              <span>Reap the Returns</span>
              <span>Heal the World</span>
              <span>Efficiency &amp; Power</span>
              <span>24*7 Support</span>
            </div>
          </div>
        </div>
      </div> */}
          <Footer />
        </>
      )}
    </div>
  );
}

export default Project;
