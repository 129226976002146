import React, { useRef, useState, useEffect } from "react";

import loadernew from "../images/loadernew.gif";
import Header from "./Header";
import Footer from "./Footer";
import img1 from "../images/CLIENTS/img1.jpg";
import img2 from "../images/CLIENTS/img2.jpg";
import img3 from "../images/CLIENTS/img3.jpg";
import img4 from "../images/CLIENTS/img4.jpg";
import img5 from "../images/CLIENTS/img5.jpg";
import img6 from "../images/CLIENTS/img6.jpg";
import img7 from "../images/CLIENTS/img7.jpg";
import img8 from "../images/CLIENTS/img8.jpg";

function Clients() {
  const [isLoading, setIsLoading] = useState(true);

  const vidRef = useRef();
  const loadimg = () => {
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  };
  useEffect(() => {
    loadimg();
  }, []);
  return (
    <div>
      {isLoading == true ? (
        <>
          <div
            style={{ zIndex: "9999999999999", height: "420px" }}
            className="text-center mt-3 mb-5"
          >
            <img
              src={loadernew}
              height="140px"
              style={{ marginTop: "250px" }}
            ></img>
          </div>
        </>
      ) : (
        <>
          <Header />
          <div className="page-header parallaxie">
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <div className="page-header-box">
                    <h1 className="text-anime">Our Clients</h1>
                    <nav className="wow fadeInUp" data-wow-delay="0.25s">
                      <ol className="breadcrumb">
                        <li className="breadcrumb-item">
                          <a href="/">Home</a>
                        </li>
                        <li
                          className="breadcrumb-item active"
                          aria-current="page"
                        >
                          Clients
                        </li>
                      </ol>
                    </nav>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="page-blog-archive">
            <div className="container">
              <div className="row">
                <div className="col-lg-3 col-md-6">
                  <div
                    className="blog-item wow fadeInUp"
                    data-wow-delay="0.25s"
                  >
                    <div className="post-featured-image">
                      {/* <a href="/Clientdetails1"> */}
                        <h1 className="image-anime" id="cont">
                          Grama panchty, Schools (GOVT)
                        </h1>
                      {/* </a> */}
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6">
                  <div className="blog-item wow fadeInUp" data-wow-delay="0.5s">
                    <div className="post-featured-image">
                      <h1 className="image-anime" id="cont">
                        GOVT Schools (MANA OORU MANA BADI)
                      </h1>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6">
                  <div
                    className="blog-item wow fadeInUp"
                    data-wow-delay="1.50s"
                  >
                     {/* <a href="/Clientdetails1"> */}
                    <h1 className="image-anime" id="cont">
                      PVNR veterinary university
                    </h1>
                    {/* </a> */}
                  </div>
                </div>
                <div className="col-lg-3 col-md-6">
                  <div
                    className="blog-item wow fadeInUp"
                    data-wow-delay="1.50s"
                  > 
                  {/* <a href="/Clientdetails1"> */}
                    <h1 className="image-anime" id="cont">
                      Talla Padmavathi College
                    </h1>
                    {/* </a> */}
                  </div>
                </div>
                <div className="col-lg-3 col-md-6">
                  <div
                    className="blog-item wow fadeInUp"
                    data-wow-delay="1.50s"
                  > 
                  {/* <a href="/Clientdetails1"> */}
                    <h1 className="image-anime" id="cont">
                      Montessori High School
                    </h1>
                    {/* </a> */}
                  </div>
                </div>
                <div className="col-lg-3 col-md-6">
                  <div
                    className="blog-item wow fadeInUp"
                    data-wow-delay="0.75s"
                  >
                     {/* <a href="/Clientdetails1"> */}
                    <h1 className="image-anime" id="cont">
                      Collector Office Suryapet
                    </h1>
                    {/* </a> */}
                  </div>
                </div>
                <div className="col-lg-3 col-md-6">
                  <div className="blog-item wow fadeInUp" data-wow-delay="1.0s">
                  {/* <a href="/Clientdetails1"> */}
                    <h1 className="image-anime" id="cont">
                      Collector Office HNK
                    </h1>
                    {/* </a> */}
                   
                  </div>
                </div>
                <div className="col-lg-3 col-md-6">
                  <div
                    className="blog-item wow fadeInUp"
                    data-wow-delay="1.25s"
                  > 
                  {/* <a href="/Clientdetails1"> */}
                    <h1 className="image-anime" id="cont">
                      Relief Hospital
                    </h1>
                    {/* </a> */}
                    
                  </div>
                </div>
                <div className="col-lg-3 col-md-6">
                  <div
                    className="blog-item wow fadeInUp"
                    data-wow-delay="1.50s"
                  > 
                  {/* <a href="/Clientdetails1"> */}
                    <h1 className="image-anime" id="cont">
                      Govt Hospital
                    </h1>
                    {/* </a> */}
                    
                  </div>
                </div>

                <div className="col-lg-3 col-md-6">
                  <div
                    className="blog-item wow fadeInUp"
                    data-wow-delay="1.50s"
                  >
                     <a href="/Clientdetails1">
                    <h1 className="image-anime" id="cont">
                      Vagdevi College
                    </h1>
                    </a>
                   
                  </div>
                </div>
               

                <div className="col-lg-3 col-md-6">
                  <div
                    className="blog-item wow fadeInUp"
                    data-wow-delay="1.50s"
                  > 
                  {/* <a href="/Clientdetails1"> */}
                    <h1 className="image-anime" id="cont">
                      Avantha Grand Hotel
                    </h1>
                    {/* </a> */}
                  </div>
                </div>
                <div className="col-lg-3 col-md-6">
                  <div
                    className="blog-item wow fadeInUp"
                    data-wow-delay="1.50s"
                  > 
                  {/* <a href="/Clientdetails1"> */}
                    <h1 className="image-anime" id="cont">
                      ITDA Utunoor
                    </h1>
                    {/* </a> */}
                  </div>
                </div>
              </div>
              
            </div>
          </div>
        
          <Footer />
        </>
      )}
    </div>
  );
}

export default Clients;
