import React, { useRef, useState, useEffect } from 'react'
import Header from './Header'
import Footer from './Footer'
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination, Navigation, EffectCoverflow } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import aboutpic1 from '../images/aboutpic1.png';
import aboutpic2 from '../images/aboutpic2.png';
import aboutpic3 from '../images/aboutpic3.png';
import arrow from '../images/arrow.png';
import impact1 from '../images/impact1.png';
import about1 from '../images/about/about1.jpg';
import about2 from '../images/about/about2.jpg';
import icon from '../images/icon.jpg';
import iconwomen from '../images/iconwomen.jpg';
import loadernew from '../images/loadernew.gif';
import relief from '../images/project/relief.jpg';
function Aboutus() {
  const [isLoading, setIsLoading] = useState(true)

  const vidRef = useRef();
  const loadimg = () => {
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  }
  useEffect(() => {

    loadimg();
  }, []);
  return (
    <div>
      {isLoading == true ? (

        <>
          <div
            style={{ zIndex: "9999999999999", height: "420px", }}
            className="text-center mt-3 mb-5"
          >
            <img src={loadernew} height="140px" style={{ marginTop: '250px' }}></img>

          </div>
        </>
      ) : (
        <>
          <Header />
          <div className="page-header parallaxie">
            <div className="container">
              <div className="row">
                <div className="col-md-12">

                  <div className="page-header-box">
                    <h1 className="text-anime">About us</h1>
                    <nav className="wow fadeInUp" data-wow-delay="0.25s">
                      <ol className="breadcrumb">
                        <li className="breadcrumb-item"><a href="/">Home</a></li>

                        <li className="breadcrumb-item active" aria-current="page">About us</li>
                      </ol>
                    </nav>
                  </div>

                </div>
              </div>
            </div>
          </div>
          <div className="about-us">
            <div className="container">
              <div className="row align-items-center">
                <div className="col-lg-6">

                  <div className="about-image">
                    <div className="about-img-1">
                      <figure className="reveal image-anime" data-aos="fade-right"
                        data-aos-offset="300"
                        data-wow-duration="1000ms"
                        data-aos-easing="ease-in-sine">
                        <img src={about1} alt="" />
                      </figure>
                    </div>
                    <div className="about-img-2">
                      <figure className="reveal image-anime" data-aos="fade-right"
                        data-aos-offset="300"
                        data-wow-duration="1000ms"
                        data-aos-easing="ease-in-sine">
                        <img src={about2} alt="" />
                      </figure>
                    </div>
                  </div>

                </div>
                <div className="col-lg-6">

                  <div className="section-title">
                    <h3 className="wow fadeInUp">About Us</h3>
                    <h2 className="text-anime" data-aos="fade-right"
                      data-aos-offset="300"
                      data-wow-duration="1000ms"
                      data-aos-easing="ease-in-sine">About Green Energy Solar</h2>
                  </div>

                  <div className="about-content wow fadeInUp" data-wow-delay="0.25s">
                    <p>Sri Balaji Solar Energies (SBSE) was established in the year 2011 by Mr. Ganesh Reddy Peddi and commenced full-fledged operations in 2013. Since then, the company has grown in stature and expertise, becoming a reputable manufacturer and trader of a diverse range of solar technology products. Our offerings include products equipped with battery backups, ensuring functionality even during night-time hours.</p>
                    <p>Mr. Ganesh Reddy Peddi, the founder of SBSE, also serves as the Managing Director, providing strategic leadership and vision to the company. Innovation is fundamental to our ethos, and under his guidance, our dedicated research team continually strives to think outside the box, developing new and radical ideas to meet evolving energy needs.</p>
                    <p>All life on Earth depends on the sun. This amazing resource radiates energy and provides us both heat and light by fusing hydrogen into helium at its core. We call this solar radiation. But only about half of this solar radiation makes it to the Earth’s surface because the rest is either absorbed or reflected by clouds and the atmosphere. Still, we receive enough sunlight from the sun to meet the power demands of all mankind - millions of times over. At Sri Balaji Solar Energies, we harness this incredible power of the sun to drive progress towards a sustainable future.</p>
                    <ul>
                      <li>Solar Inverter Setup</li>
                      <li>Battery Storage Solutions</li>
                      <li>Solar Material Financing</li>
                      <li>24 X 7 Call &amp; Chat Support</li>
                      <li>Proven Track Record</li>
                      <li>Customer-Centric Approach</li>
                    </ul>
                  </div>

                </div>
              </div>
            </div>
          </div>
          <div className="why-choose-us">
            <div className="container">
              <div className="row">
                <div className="col-md-12">

                  <div className="section-title">
                    <h3 className="wow fadeInUp">Why Choose Us</h3>
                    <h2 className="text-anime">Providing Solar Energy Solutions</h2>
                  </div>

                </div>
              </div>
              <div className="row">
                <div className="col-lg-3 col-md-6 mb-4">

                  <div className="why-choose-item wow fadeInUp" data-wow-delay="0.25s">
                    <div className="why-choose-image">
                      <img src="images/whyus-1.jpg" alt="" />
                    </div>
                    <div className="why-choose-content">
                      <div className="why-choose-icon">
                        <img src="images/icon-whyus-1.svg" alt="" />
                      </div>
                      <h3>Efficiency &amp; Power</h3>
                      <p>The sun provides more than enough energy to meet the whole world's energy needs, and unlike fossil fuels, it won't run out anytime soon.</p>
                    </div>
                  </div>

                </div>
                <div className="col-lg-3 col-md-6 mb-4">

                  <div className="why-choose-item wow fadeInUp" data-wow-delay="0.5s">
                    <div className="why-choose-image">
                      <img src="images/whyus-2.jpg" alt="" />
                    </div>
                    <div className="why-choose-content">
                      <div className="why-choose-icon">
                        <img src="images/icon-whyus-2.svg" alt="" />
                      </div>
                      <h3>Trust &amp; Warranty</h3>
                      <p>A solar panel manufacturer may provide a product or performance warranty of 25 years that will cover a replacement panel should you need it.</p>
                    </div>
                  </div>

                </div>
                <div className="col-lg-3 col-md-6 mb-4">

                  <div className="why-choose-item wow fadeInUp" data-wow-delay="0.75s">
                    <div className="why-choose-image">
                      <img src="images/whyus-3.jpg" alt="" />
                    </div>
                    <div className="why-choose-content">
                      <div className="why-choose-icon">
                        <img src="images/icon-whyus-3.svg" alt="" />
                      </div>
                      <h3>High Quality Work</h3>
                      <p>High-quality solar panels are engineered to capture and convert sunlight into electricity with optimal efficiency.</p>
                    </div>
                  </div>

                </div>
                <div className="col-lg-3 col-md-6 mb-4">

                  <div className="why-choose-item wow fadeInUp" data-wow-delay="1.0s">
                    <div className="why-choose-image">
                      <img src="images/whyus-4.jpg" alt="" />
                    </div>
                    <div className="why-choose-content">
                      <div className="why-choose-icon">
                        <img src="images/icon-whyus-4.svg" alt="" />
                      </div>
                      <h3>24*7 Support</h3>
                      <p>Customer satisfaction for us starts with providing top class products at affordable prices that will work trouble free for a long time. Our service staff always .</p>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
          <div className="our-process ">
            <div className="container">
              <div className="row">
                <div className="col-md-12">

                  <div className="section-title">
                    <h3 className="wow fadeInUp">Our Latest Process</h3>
                    <h2 className="text-anime">Our Work Process</h2>
                  </div>

                </div>
              </div>
              <div className="row">
                <div className="col-md-4">

                  <div className="step-item step-1 wow fadeInUp" data-wow-delay="0.25s">
                    <div className="step-header">
                      <div className="step-icon">
                        <figure>
                          <img src="images/icon-step-1.svg" alt="" />
                        </figure>
                        <span className="step-no">01</span>
                      </div>
                    </div>
                    <div className="step-content">
                      <h3>Project Planing</h3>
                      <p>There are typically five project life cycle phases: initiation, planning, execution, monitoring and controlling, and closure.</p>
                    </div>
                  </div>

                </div>
                <div className="col-md-4">

                  <div className="step-item step-2 wow fadeInUp" data-wow-delay="0.5s">
                    <div className="step-header">
                      <div className="step-icon">
                        <figure>
                          <img src="images/icon-step-2.svg" alt="" />
                        </figure>
                        <span className="step-no">02</span>
                      </div>
                    </div>
                    <div className="step-content">
                      <h3>Research &amp; Analysis</h3>
                      <p>Sri Balaji research covers photovoltaics, concentrating solar power, solar grid and systems integration, and market research and analysis.</p>
                    </div>
                  </div>

                </div>
                <div className="col-md-4">

                  <div className="step-item wow fadeInUp" data-wow-delay="0.75s">
                    <div className="step-header">
                      <div className="step-icon">
                        <figure>
                          <img src="images/icon-step-3.svg" alt="" />
                        </figure>
                        <span className="step-no">03</span>
                      </div>
                    </div>
                    <div className="step-content">
                      <h3>Solar Installation</h3>
                      <p>Your solar installer preps your roof and ensures the tiles are correctly attached. then install electrical wiring that connects the solar panels to the electric panel inside of it.</p>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
          <div className="infobar">
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <div className="cta-box">
                    <div className="row align-items-center">
                      <div className="col-lg-4">

                        <div className="cta-image">
                          <figure className="image-anime">
                            <img src="images/cta-image.jpg" alt="" />
                          </figure>
                        </div>

                      </div>
                      <div className="col-lg-8">

                        <div className="cta-content">
                          <div className="phone-icon">
                            <figure>
                              <img src="images/icon-cta-phone.svg" alt="" />
                            </figure>
                          </div>
                          <h3 className="text-anime">Have Questions? <span>Call Us</span>+91 951 595 49 23</h3>
                          <p className="wow fadeInUp" data-wow-delay="0.25s">It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.</p>
                        </div>

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="latest-projects">
            <div className="container">
              <div className="row">
                <div className="col-md-12">

                  <div className="section-title">
                    <h3 className="wow fadeInUp">Latest Project</h3>
                    <h2 className="text-anime">Our Latest Projects</h2>
                  </div>

                </div>
              </div>
              <div className="row">
                <div className="col-lg-4 col-md-6 mb-4">
                  <div className="project-item wow fadeInUp" data-wow-delay="0.25s">
                    <div className="project-image">
                      <figure>
                        <img src="images/tallaimg.jpg" alt="" />
                      </figure>
                     
                    </div>
                    <div className="project-content">
                      <h2>Talla Padmavathi 56 kw</h2>

                    </div>
                    <div className="project-link">
                      <a href="/Projects"><img src="images/icon-link.svg" alt="" /></a>
                    </div>
                  </div>

                </div>
                <div className="col-lg-4 col-md-6">

                  <div className="project-item wow fadeInUp" data-wow-delay="0.5s">
                    <div className="project-image">
                      <figure>
                        <img src={relief} alt="" height={300} />
                      </figure>
                    </div>
                    <div className="project-content">
                      <h2>Relief hospital arepally 54kw</h2>

                    </div>
                    <div className="project-link">
                      <a href="/Projects"><img src="images/icon-link.svg" alt="" /></a>
                    </div>
                  </div>

                </div>
                <div className="col-lg-4 col-md-6">

                  <div className="project-item wow fadeInUp" data-wow-delay="0.75s">
                    <div className="project-image">
                      <figure>
                        <img src="images/about-1.jpg" alt="" height={300} />
                      </figure>
                    </div>
                    <div className="project-content">
                      <h2>Vagdevi junior college 30 kw</h2>

                    </div>
                    <div className="project-link">
                      <a href="/Projects"><img src="images/icon-link.svg" alt="" /></a>
                    </div>
                  </div>

                </div>

              </div>
            </div>
          </div>

          <div className="stat-counter mt-4">
            <div className="container">
              <div className="row">
                <div className="col-lg-3 col-md-6">

                  <div className="counter-item">
                    <div className="counter-icon">
                      <img src="images/icon-project.svg" alt="" />
                    </div>
                    <div className="counter-content">
                      <h3><span className="counter">1000</span>+</h3>
                      <p>Project Done</p>
                    </div>
                  </div>

                </div>
                <div className="col-lg-3 col-md-6">

                  <div className="counter-item">
                    <div className="counter-icon">
                      <img src="images/icon-happy-clients.svg" alt="" />
                    </div>
                    <div className="counter-content">
                      <h3><span className="counter">1200</span>+</h3>
                      <p>Happy Clients</p>
                    </div>
                  </div>

                </div>
                <div className="col-lg-3 col-md-6">

                  <div className="counter-item">
                    <div className="counter-icon">
                      <img src="images/icon-award.svg" alt="" />
                    </div>
                    <div className="counter-content">
                      <h3><span className="counter">850</span>+</h3>
                      <p>Award Winning</p>
                    </div>
                  </div>

                </div>
                <div className="col-lg-3 col-md-6">

                  <div className="counter-item">
                    <div className="counter-icon">
                      <img src="images/icon-ratting.svg" alt="" />
                    </div>
                    <div className="counter-content">
                      <h3><span className="counter">1100</span>+</h3>
                      <p>Rating Customer</p>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
          <div className="testimonials">
            <div className="container">
              <div className="row">
                <div className="col-md-12">

                  <div className="section-title">
                    <h3 className="wow fadeInUp">Testimonials</h3>
                    <h2 className="text-anime">Words From Our Customer</h2>
                  </div>

                </div>
              </div>
              <div className="row">
                <div className="col-md-12">

                  <div className="testimonial-slider">
                    <div className="swiper">
                      <div className="swiper-wrapper">
                        <Swiper

                          centeredSlides={true}
                          coverflowEffect={{
                            rotate: 50,
                            stretch: 0,
                            depth: 100,
                            modifier: 1,
                            slideShadows: false,
                          }}
                          spaceBetween={30}
                          autoplay={{
                            delay: 2500,
                          }}
                          loop={true}
                          slidesPerView={3}
                          modules={[Autoplay, Pagination, EffectCoverflow]}
                          className="mySwiper"
                          breakpoints={{
                            0: {
                              slidesPerView: 1,
                            },
                            400: {
                              slidesPerView: 1,
                            },
                            639: {
                              slidesPerView: 1,
                            },
                            865: {
                              slidesPerView: 3
                            },
                            1000: {
                              slidesPerView: 3
                            },
                            1500: {
                              slidesPerView: 3
                            },
                            1700: {
                              slidesPerView: 3
                            }
                          }}
                        >


                          <SwiperSlide>
                            <div className="swiper-slide">
                              <div className="testimonial-item">
                                <div className="testimonial-header">
                                  <div className="testimonial-author-img">
                                    <figure className="image-anime">
                                      <img src={icon} alt="" />
                                    </figure>
                                    <div className="icon-quote">
                                      <img src="images/icon-quote.svg" alt="" />
                                    </div>
                                  </div>
                                  <h2>mahender reddy pundra</h2>
                                  <p>(Customer)</p>
                                  <div className="testimonial-rating">
                                    <img src="images/icon-star.svg" alt="" />
                                  </div>
                                </div>
                                <div className="testimonial-body">
                                  <p>No power bill after taken 3kw before monthly getting 3500 to 4200 now only power bill 278 thanks.one time investment Rs.140000.</p>
                                </div>
                              </div>
                            </div>
                          </SwiperSlide>

                          <SwiperSlide>
                            <div className="swiper-slide">
                              <div className="testimonial-item">
                                <div className="testimonial-header">
                                  <div className="testimonial-author-img">
                                    <figure className="image-anime">
                                      <img src={iconwomen} alt="" />
                                    </figure>
                                    <div className="icon-quote">
                                      <img src="images/icon-quote.svg" alt="" />
                                    </div>
                                  </div>
                                  <h2>Sai</h2>
                                  <p>(Customer)</p>
                                  <div className="testimonial-rating">
                                    <img src="images/icon-star.svg" alt="" />
                                  </div>
                                </div>
                                <div className="testimonial-body">
                                  <p>Ongrid solar super for home because current bill is reduced to lot Thanks to Sri Balaji solar for giving solar plant in subsidy</p>
                                </div>
                              </div>
                            </div>
                          </SwiperSlide>

                          <SwiperSlide>
                            <div className="swiper-slide">
                              <div className="testimonial-item">
                                <div className="testimonial-header">
                                  <div className="testimonial-author-img">
                                    <figure className="image-anime">
                                      <img src={icon} alt="" />
                                    </figure>
                                    <div className="icon-quote">
                                      <img src="images/icon-quote.svg" alt="" />
                                    </div>
                                  </div>
                                  <h2>Yesh</h2>
                                  <p>(Customer)</p>
                                  <div className="testimonial-rating">
                                    <img src="images/icon-star.svg" alt="" />
                                  </div>
                                </div>
                                <div className="testimonial-body">
                                  <p>Solar is very important present generation for reducing power bill with solar ongrid system. previously we got 3000 to 3800 but after taken solar there is only 278 power bill coming thanks to solar company.</p>
                                </div>
                              </div>
                            </div>
                          </SwiperSlide>

                          <SwiperSlide>
                            <div className="swiper-slide">
                              <div className="testimonial-item">
                                <div className="testimonial-header">
                                  <div className="testimonial-author-img">
                                    <figure className="image-anime">
                                      <img src={iconwomen} alt="" />
                                    </figure>
                                    <div className="icon-quote">
                                      <img src="images/icon-quote.svg" alt="" />
                                    </div>
                                  </div>
                                  <h2>Swarna</h2>
                                  <p>(Customer)</p>
                                  <div className="testimonial-rating">
                                    <img src="images/icon-star.svg" alt="" />
                                  </div>
                                </div>
                                <div className="testimonial-body">
                                  <p>Nice team i contact lots members for my solar system no one not responding finally in warangal local we find SRI BALAJI SOLAR they are doing good service within the 3 hours our solar system worked as a new thanks Balaji team.</p>
                                </div>
                              </div>
                            </div>
                          </SwiperSlide>
                          ...
                        </Swiper>

                      </div>
                      <div className="swiper-pagination" />
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>


          {/* <div className="footer-ticker">
        <div className="scrolling-ticker">
          <div className="scrolling-ticker-box">
            <div className="scrolling-content">
              <span>Generate Your Own Power</span>
              <span>Reap the Returns</span>
              <span>Heal the World</span>
              <span>Efficiency &amp; Power</span>
              <span>24*7 Support</span>
            </div>
            <div className="scrolling-content">
              <span>Generate Your Own Power</span>
              <span>Reap the Returns</span>
              <span>Heal the World</span>
              <span>Efficiency &amp; Power</span>
              <span>24*7 Support</span>
            </div>
          </div>
        </div>
      </div>
       */}

          <Footer />
        </>
      )}
    </div>
  )
}

export default Aboutus