import React from "react";
import Whatsapp from "./Whatsapp";
import Sidebar from "./Sidebar";

function Footer() {
  return (
    <div>
      <Whatsapp />
      <Sidebar />

      <footer className="main-footer">
        <div className="container ">
          <div className="row">
            <div className="col-md-12">
              <div className="mega-footer">
                <div className="row ">
                  <div className="col-lg-3 col-md-12">
                    <div className="footer-about">
                      <figure>
                        <img src="images/footerwhite.png" alt="" width={250} />
                      </figure>
                      <p>
                        (SBSE) was established in the year 2011 by Mr. Ganesh
                        Reddy Peddi and commenced full-fledged operations in
                        2013. Since then, the company has grown in stature and
                        expertise
                      </p>
                    </div>

                    {/* <div className="footer-social-links">
                      <ul>
                        <li><a href="#"><i className="fa-brands fa-facebook-f" /></a></li>
                        <li><a href="#"><i className="fab fa-twitter" /></a></li>
                        <li><a href="#"><i className="fa-brands fa-linkedin-in" /></a></li>
                        <li><a href="#"><i className="fa-brands fa-instagram" /></a></li>
                 
                      </ul>
                    </div> */}
                  </div>
                  <div className="col-lg-3 col-md-4">
                    <div className="footer-links">
                      <h2>Quick Links</h2>
                      <ul>
                        <li>
                          <a href="/">Home</a>
                        </li>
                        <li>
                          <a href="/about">About Us</a>
                        </li>

                        <li>
                          <a href="/contact">Contact Us</a>
                        </li>
                        <li>
                          <a href="/termsandcontions">Terms and Conditions</a>
                        </li>
                        <li>
                          <a href="/privacypolicy">Privacy Policy</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-4">
                    <div className="footer-links">
                      <h2>Products</h2>
                      <ul>
                        <li>
                          <a href="/industrial-rooftops">
                            Industrial Solar Rooftops{" "}
                          </a>
                        </li>
                        <li>
                          <a href="/commercial-rooftops">
                            Commercial Solar Rooftops
                          </a>
                        </li>
                        <li>
                          <a href="/Residential-Rooftops">
                            Residential Solar Rooftops
                          </a>
                        </li>
                        <li>
                          <a href="/On-Grid-Connected-Solar-Pvsystems">
                            On-Grid Connected Solar Pv systems
                          </a>
                        </li>
                        <li>
                          <a href="/Off-Grid-Connected-Solar-Pvsystems">
                            Off-Grid Connected Solar Pv systems
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-4">
                    <div className="footer-links">
                      <h2>Contact Us</h2>
                      {/* <ul>
                        <li><a href="/SolarWaterPumpingSystem">Solar Water Pumping System</a></li>
                        <li><a href="/SolarFencingSystem">Solar Fencing System</a></li>
                        <li><a href="/LedStreetLights">Led Street Lights</a></li>
                        <li><a href="/SolarStreetLights">Solar Street Lights</a></li>

                        <li><a href="/solar-waterheater">Solar Water Heater</a></li>


                      </ul>  */}
                      <div
                        className="footer-contact-box wow fadeInUp"
                        data-wow-delay="0.25s"
                        style={{ paddingTop: "2px" }}
                      >
                        <div className="contact-icon-box">
                          <img src="images/icon-email.svg" alt="" />
                        </div>
                        <div className="footer-contact-info">
                          {/* <h3>Support &amp; Email</h3> */}
                          <a href="mailto: sbse4444@gmail.com">
                            {" "}
                            <p>sbse4444@gmail.com</p>
                          </a>
                        </div>
                      </div>
                      <div
                        className="footer-contact-box wow fadeInUp"
                        data-wow-delay="0.5s"
                        style={{ paddingTop: "50px" }}
                      >
                        <div className="contact-icon-box">
                          <img src="images/icon-phone.svg" alt="" />
                        </div>
                        <div className="footer-contact-info">
                          {/* <h3>Customer Support</h3> */}
                          <a href="tel:+ 919515954923">
                            {" "}
                            <p>+919032041774</p>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-contact">
          <div className="container">
            <div className="row">
              <div className="col-lg-4">
                <div
                  className="footer-contact-box wow fadeInUp"
                  data-wow-delay="0.75s"
                >
                  <div className="contact-icon-box">
                    <img src="images/icon-location.svg" alt="" />
                  </div>
                  <div className="footer-contact-info mb-3">
                    {/* <h3 className="mt-0">Our Location</h3> */}
                    <h3>CORPORATE OFFICE(Head Office)</h3>
                    <p>
                      <b>SRI BALAJI SOLAR ENERGIES (SBSE)</b>
                      <br />
                      6-1-34,1st Floor <br /> Chinthala Narasimhulu Complex{" "}
                      <br /> Near Mission Hospital,
                      <br /> Lashkar Bazar, Hanamkonda, <br />
                      Warangal, Telangana 506001
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div
                  className="footer-contact-box wow fadeInUp"
                  data-wow-delay="0.75s"
                >
                  <div className="contact-icon-box">
                    <img src="images/icon-location.svg" alt="" />
                  </div>
                  <div className="footer-contact-info">
                    <h3 className="mt-0">Hyderabad Branch</h3>
                    {/* <h3>Co-orperate office(head office)</h3> */}

                    <p>
                      H NO 15-74/18, <br />
                      Chengicherla X Road, <br /> Medipally, <br />
                      Hyderabad - 500098.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div
                  className="footer-contact-box wow fadeInUp"
                  data-wow-delay="0.75s"
                >
                  <div className="contact-icon-box">
                    <img src="images/icon-location.svg" alt="" />
                  </div>
                  <div className="footer-contact-info">
                    <h3 className="mt-0">Warangal Branch:</h3>
                    {/* <p>Co-orperate office(head office)</p> */}

                    <p>
                      2nd Floor, <br /> Lashkar Bazar,
                      <br /> Hanamkonda,
                      <br /> Warangal-506001
                      <br />
                      Besides Vasan Eye Care Hospital
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="footer-contact">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <div
                    className="footer-contact-box wow fadeInUp text-center"
                    data-wow-delay="0.75s"
                  >
                    <div className="footer-contact-info ">
                      <h3>Our Locations</h3>
                      <p>
                        <b style={{ fontSize: "20px" }}>Telangana : </b>{" "}
                        Karimnagar - Kothagudem - Adilabad - Khammam - Narsampet
                        - Mancherial
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="footer-copyright">
            <div className="row">
              <div className="col-md-12">
                <div className="footer-copyright-text">
                  <p>
                    © 2024.{" "}
                    <a href="#" style={{ color: "white" }}>
                      SriBlaji SolarEnergies.
                    </a>{" "}
                    All Rights Reserved - Designed by{" "}
                    <a
                      href="https://digitalraiz.com/"
                      target="_blank"
                      style={{ color: "#03264e" }}
                    >
                      {" "}
                      Digitalraiz
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>

        </div>
      </footer>
    </div>
  );
}

export default Footer;
