import React, { useRef, useState, useEffect } from "react";
import about1 from "../images/about/about1.jpg";
import loadernew from "../images/loadernew.gif";
import Header from "./Header";
import Footer from "./Footer";

function Awards() {
  const [isLoading, setIsLoading] = useState(true);

  const vidRef = useRef();
  const loadimg = () => {
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  };
  useEffect(() => {
    loadimg();
  }, []);
  return (
    <div>
      {isLoading == true ? (
        <>
          <div
            style={{ zIndex: "9999999999999", height: "420px" }}
            className="text-center mt-3 mb-5"
          >
            <img
              src={loadernew}
              height="140px"
              style={{ marginTop: "250px" }}
            ></img>
          </div>
        </>
      ) : (
        <>
          <Header />
          <div className="page-header parallaxie">
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <div className="page-header-box">
                    <h1 className="text-anime">Our Awards</h1>
                    <nav className="wow fadeInUp" data-wow-delay="0.25s">
                      <ol className="breadcrumb">
                        <li className="breadcrumb-item">
                          <a href="/">Home</a>
                        </li>
                        <li
                          className="breadcrumb-item active"
                          aria-current="page"
                        >
                          Award
                        </li>
                      </ol>
                    </nav>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <section className="project-details pt-4 pb-4">
            <div className="container">
              <div className="project-details__content">
                <div className="row justify-content-center">
                  <div className="col-xl-7">
                    <div
                      className="project-details__content-left wow animated flipInX animated animated"
                      style={{ visibility: "visible" }}
                    >
                      <div className="sec-title light">
                        <div className="sub-title-outer">
                          <span className="sub-title text-dark">
                            Our Awards
                          </span>
                          <span className="divider" />
                        </div>
                      </div>
                      <h3 className="mb-4">
                        Award-Winning Excellence: Illuminate Your World with{" "}
                        <br></br> Sri Balaji Solar Energies!
                      </h3>
                      <p className="text-justify">
                        Ranked No.2 Solar Company by Telangana State Renewable
                        Energy Development Corporation, TSREDCO (Govt of
                        Telangana) &amp; MNRE (Govt of India), Sri Balaji Solar
                        Energies has been recognized for relentless commitment
                        to excellence &amp; Sustainability.
                      </p>
                    </div>
                    <div className="row">
                      <div className="col-lg-6">
                        <p>
                          <a>
                            <img
                              src="images/about-6.jpg"
                              alt="Image"
                              style={{height:"300px"}}
                              className="img-fluid rounded"
                            />
                          </a>
                        </p>
                        <h6>
                          <a>The State Leadership Awards in Telangana 2024</a>
                        </h6>
                      </div>
                      <div className="col-lg-6">
                        <p>
                          <a>
                            <img
                              src="images/about-8.jpg"
                              alt="Image"
                          style={{height:"300px"}}
                              className="img-fluid rounded"
                            />
                          </a>
                        </p>
                        <h6>
                          <a>Telangana &amp; A.P Annual Solar Awards 2023</a>
                        </h6>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-5">
                    <p>
                      <img src={about1} alt="Image" className="img-fluid" />
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <Footer />
        </>
      )}
    </div>
  );
}

export default Awards;
